import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
    Login,
    FirstPage,
    Doctor,
    MainLocationPage,
    OnLoad,
    Away,
    AlreadyConnected
} from './Pages'

class AllRoutes extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Routes>
                {/* <Navigate  exact from="/" to={"/on-load"} /> */}
                <Route exact path="/first-page" element={<FirstPage/>} />
                {/* <Route exact path="/on-load" element={OnLoad} /> */}
                <Route exact path="/login" element={<Login {...this.props} />} />
                <Route exact path="/reset-password" element={<Login {...this.props} />} />
                <Route exact path="/receptionist" element={<Doctor {...this.props} />} />
                <Route exact path="/mainlocationpage/:username/:clientid" element={<MainLocationPage {...this.props} />} />
                <Route exact path="/location/away" element={<Away {...this.props} />} />
                <Route exact path="/already-connected" element={<AlreadyConnected/>} />
                <Route exact to={"/"} />{/*or <Navigate/>*/}
            </Routes>
        )
    }
}

export default AllRoutes;