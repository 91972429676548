import React, {useEffect} from 'react';
import './style.css';
import ColorButton from '../../Components/Patient/ColorButton';
import BackButton from '../../Components/Patient/BackButton';
import Str from '../../constants/string';
import PageConst from '../../constants/patientpage';
import IconColorButton from '../../Components/Patient/IconColorButton';
import HairImage from '../../assets/images/hair_icon.png';
import Color from '../../constants/usercolor';
import Storages from '../../constants/storages';
import { HiddenMenu } from '../../Components/Location/HiddenMenu';

export default function Appointment (props) {
    const { isCalling, moveToPage } = props;

    useEffect(() => {
      window.goBackToWelcomeTimeout = setTimeout(()=>{
        moveToPage(PageConst.WELCOME)
      }, 30 * 1000)
      return (()=>{
        clearTimeout(window.goBackToWelcomeTimeout)
      })
    }, [])

    const handleYes = () => {
        if (isCalling) {
            return;
        }
        if(localStorage.getItem(Storages.LOCAL_KIOSK_USER)==='0'){
            props.handleShowCallDialog(Str.STR_INITIAL_EVAL, false);
            return
        }
        props.handleShowCallDialog(Str.STR_NEW_PATIENT, false);
    }

    const handleNo = () => {
        if (isCalling) {
            return;
        }
        if(localStorage.getItem(Storages.LOCAL_KIOSK_USER)==='0'){
            props.handleShowCallDialog(Str.STR_CHECK_IN, false);
            return
        }
        props.moveToPage(PageConst.SELFCHECK);
    }

    const handleCall = () => {
        if (isCalling) {
            return;
        }
        props.handleShowCallDialog(Str.STR_NORMAL, false);
    }

    const handleBack = () => {
        if (isCalling) {
            return;
        }
        props.moveToPage(PageConst.WELCOME);
    }

    return (
        <div className='root'>
            <HiddenMenu/>
            <img className='top_tap' id='call_top_tap' src={window.locationTopBanner} alt="top_tap" />
            <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
            <div className='center_container'>
                <label className='main_lab'>{window.i18n.getString("firstAppointmentQuestion")}</label>
                <div className='bottom_div'>
                    <ColorButton onClicked={handleYes} Color={isCalling ? Color.GRAY_BTN_COLOR : Color.GREEN_BTN_COLOR} 
                      Title={window.i18n.getString("yes")} />
                    <ColorButton onClicked={handleNo} Color={isCalling ? Color.GRAY_BTN_COLOR : Color.YELLOW_BTN_COLOR} 
                      Title={window.i18n.getString("no")} />
                </div>
            </div>
            <div id="call_div">
                <IconColorButton onClicked={handleCall} Color={isCalling ? Color.GRAY_BTN_COLOR : Color.PURPLE_BTN_COLOR} 
                  Icon={HairImage} Title={window.i18n.getString("callReceptionForHelp")} />
            </div>
            <div id="back_div">
                <BackButton onClicked={handleBack} />
            </div>
        </div>
    )
}

Appointment.prototype = {

}
