import React, { useEffect, useRef, useState } from "react";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./style.css";
import "./remotelocationvideoview.css";
import UnloginImage1 from '../../assets/images/unlogin_1.png';
import UnloginImage2 from '../../assets/images/unlogin_2.png';
import UnloginImage3 from '../../assets/images/unlogin_3.png';
import UnloginImage4 from '../../assets/images/unlogin_4.png';
import NightsStayOutlinedIcon from '@mui/icons-material/NightsStayOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import { sendReloadLocationToSocket } from '../../context/socket'
import { Box, Tooltip } from '@mui/material'
import PowerOffOutlinedIcon from '@mui/icons-material/PowerOffOutlined';
import { useSelector } from 'react-redux';
import RemoteLocationVideoView from "./RemoteLocationVideoView";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ContextMenu from "../Reception/ContextMenu";
import { toast } from 'react-toastify';
import Constant from "../../constants/constant";
import apiService from '../../services/api';

const ListLocations = React.memo((props) => {
  const { onlineLocations, allLocations=[], width, height,
    callState,locationAreaRef,handleDeviceManagement,handleOpenMessagingBoard,
    toggleShareScreen, startMonitorCall, monitorCallStatus, captureLocationCameraImage
   } = props;
  const LoginedImageList = [UnloginImage1, UnloginImage2, UnloginImage3, UnloginImage4];
  const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
  const [showContextMenu,setShowContextMenu]=useState(false)
  const contextMenuRef=useRef()
  const [contextLocation,setContextLocation]=useState(null)
  const [contextMenuDisabled, setContextMenuDisabled]=useState({
    reload:false,
    deviceManagement:true,
    shareScreen:true,
    monitor:true,
    messagingBoard:false,
    takePicture:true,
    hangupHeldCall:true,
  })

  const reloadLocation = (e, username) => {
    e.stopPropagation();
    let loc=onlineLocations.find((l)=>l.username===username)
    if(loc && loc.inCall) return
    sendReloadLocationToSocket(username)
  }

  const setContextMenuDisabledOptions = (location) => {
    let options = contextMenuDisabled
    if(location.inCall){
      options.reload=true
      options.deviceManagement=true
      options.shareScreen=true
      options.monitor=true
      options.takePicture=true
      options.messagingBoard=false
      options.hangupHeldCall=true
      if(!callState && !monitorCallStatus){ // isManager is checked inside the context menu itself
        options.monitor=false
      }
      if(location.status == Constant.HOLD){
        options.hangupHeldCall=false
      }
    } else if(location.audiotrack || location.videotrack || location.user){
      options.reload=false
      options.deviceManagement=false
      options.shareScreen=false
      options.messagingBoard=false
      options.takePicture=false
      options.monitor=true
      options.hangupHeldCall=true
    } else {
      options.reload=false
      options.deviceManagement=true
      options.shareScreen=true
      options.monitor=true
      options.messagingBoard=true
      options.takePicture=true
      options.hangupHeldCall=true
    }
    setContextMenuDisabled(options)
  }

  useEffect(()=>{
    if(!showContextMenu ||  !contextLocation || !contextLocation.username) return
    let loc=onlineLocations.find((l)=>l.username===contextLocation.username)
    if(!loc) loc=allLocations.find((l)=>l.username===contextLocation.username)
    if(!loc) {
      toast.warning("location not found")
      handleCloseContextMenu()
      return
    }

    setContextMenuDisabledOptions(loc)
    setContextLocation(loc)
  }, [onlineLocations, allLocations])

  const onRightClick = (e,username)=>{
    e.preventDefault()
    let x=e.clientX 
    let y=e.clientY 
    // if(receptionSidebar && !rightSidebar) x=x-300 // sidebar width is 300px
    var listAreaWidth=locationAreaRef.current.clientWidth
    var listAreaHeight=locationAreaRef.current.clientHeight 

    const contextWidth= 200 // min width
    const contextHeight= 320
    // diff height of scroll with client height
    let scrollHeight=locationAreaRef.current.scrollHeight
    if(scrollHeight> listAreaHeight) {
      // y+= scrollHeight-listAreaHeight
    }
    if(listAreaWidth < (e.clientX + contextWidth)) contextMenuRef.current.style.left=x-contextWidth+"px"
    else contextMenuRef.current.style.left=x+"px"
    if(listAreaHeight < (e.clientY + contextHeight) ) contextMenuRef.current.style.top=y-contextHeight+"px"   
    else contextMenuRef.current.style.top=y+"px"

    let loc=onlineLocations.find((l)=>l.username===username)
    if(!loc) loc=allLocations.find((l)=>l.username===username)
    if(!loc) return toast.warning("Location not found")
  
    setContextMenuDisabledOptions(loc)
    setContextLocation(loc)
    setShowContextMenu(true)
  }

  const handleCloseContextMenu=()=>{
    setContextLocation(null)
    setShowContextMenu(false)
  }

  function getUnLogedImage(logo, index) {
    let ret = "";

    if (logo === "" || logo === null) {
      ret = LoginedImageList[index % 4];
    } else if (logo.startsWith("http://") || logo.startsWith("https://")) {
      ret = logo;
    } else {
      ret = process.env.REACT_APP_UPLOAD_URL + logo
    }

    return ret;
  }

  const awakeLocation = async (username) => {
    try{
      await apiService.updateLastActivity(username)
      await apiService.sendMessage({to: username, event: 'unSleep', msg:'wake up'})
    } catch (err) {
      console.error(1469, err)
      toast.error(err.message, {autoClose: 10000})
    }
  }

  return (
    <>
      {allLocations.map((oneLocation, oneLocationIndex) => {
        var locUsername=oneLocation.username
        const isSec = !oneLocation.isPrimary;
        if (isSec && !showSecondaryLocations) return;

        var onlineIndex = onlineLocations.findIndex((rl) => rl.username == oneLocation.username)
        if (onlineIndex != -1 && oneLocation.isSleep != 1 && oneLocation.isOnline != 0 && !oneLocation.locationIsAway) {
          var online = onlineLocations[onlineIndex]
          if (online.videotrack === null) {
            return (
              <Box className={'location_box_root' + (isSec ? ' location_box_secondary' : "")}
                onContextMenu={(e)=>{
                  e.preventDefault()
                  onRightClick(e,locUsername)
                }}
                style={{ cursor: 'pointer', width, height }} key={oneLocationIndex}
                onClick={() => { awakeLocation(locUsername) }}>
                <div className='location_box_img'
                  style={{
                    display: "flex",
                    backgroundImage: `url(${getUnLogedImage(oneLocation.logo, oneLocationIndex)})`,
                    backgroundPosition: "center", backgroundSize: "cover"
                  }}
                  alt={`unloged_img_${oneLocationIndex}`} >

                </div>
                <Tooltip title='No video track'>
                  <div className='location_box_name'>
                    <VideocamOffOutlinedIcon />
                    <label style={{ margin: '0 5px' }}>
                      {oneLocation.locationname}
                      {isSec &&
                        <sup style={{ color: "#f3c200" }} title='Secondary location'>&nbsp;2nd</sup>
                      }
                    </label>
                  </div>
                </Tooltip>
              </Box>
            )
          } else {
            return (
              <div key={oneLocationIndex} className={'location_box_root' + (isSec ? " location_box_secondary" : "")}>
                <RemoteLocationVideoView
                  handleClickLocationItem={(jitsiUserId) => props.handleClickLocationItem(jitsiUserId)}
                  handleLocationCall={() => props.handleLocationCall()}
                  rightClick={(e,username)=>onRightClick(e,username)}
                  track={online.videotrack}
                  othertrack={online.othertrack}
                  video_tag_id={online.videotrack.getParticipantId() + 'location' + online.videotrack.getType()}
                  locationName={online.locationname}
                  calltag={online.calltag}
                  calltagColor={online.calltagColor}
                  status={online.status}
                  username={online.username}
                  width={isSec ? width-5 : width} // secondary locations have 2.5px border
                  height={isSec ? height-5 : height} // secondary locations have 2.5px border
                  id={online.id}
                  holdTimer={online.holdTimer}
                  currentLangCode={online.currentLangCode}
                  inCall={online.inCall}
                  callBy={online.callBy}
                />
              </div>
            )
          }
        } else if (oneLocation.isSleep == 1 && oneLocation.isOnline == 1 && !oneLocation.locationIsAway) {
          return (
            <Box className={'location_box_root' + (isSec ? "  location_box_secondary" : "")} 
              style={{ cursor: 'pointer', width, height }} key={oneLocationIndex}
              onContextMenu={(e)=>{
                e.preventDefault()
                onRightClick(e,locUsername)
              }}
              onClick={() => { awakeLocation(locUsername) }}>
              <div className='location_box_img'
                style={{
                  display: "flex",
                  backgroundImage: `url(${getUnLogedImage(oneLocation.logo, oneLocationIndex)})`,
                  backgroundPosition: "center", backgroundSize: "cover"
                }}
                alt={`unloged_img_${oneLocationIndex}`} >
              </div>
              <div className='location_box_name' title="This location is on sleep screen">
                <NightsStayOutlinedIcon style={{ marginLeft: "1px" }} />
                <label style={{ margin: '0 5px' }}>
                  {oneLocation.locationname}
                  {isSec &&
                    <sup style={{ color: "#f3c200" }} title='Secondary location'>&nbsp;2nd</sup>
                  }
                </label>
              </div>
            </Box>
          )
        } else if (!oneLocation.isOnline) {
          return (
            <Box className={'location_box_root' + (isSec ? "  location_box_secondary" : "")} 
              style={{ width, height }} key={oneLocationIndex}>
              <div className='location_box_img'
                style={{
                  backgroundImage: `url(${getUnLogedImage(oneLocation.logo, oneLocationIndex)})`,
                  backgroundPosition: "center", backgroundSize: "cover"
                }}
                alt={`unloged_img_${oneLocationIndex}`}>
              </div>
              <div className='location_box_name' title="This location is offline">
                <PowerOffOutlinedIcon />
                <label style={{ margin: '0 5px' }}>
                  {oneLocation.locationname}
                  {isSec &&
                    <sup style={{ color: "#f3c200" }} title='Secondary location'>&nbsp;2nd</sup>
                  }
                </label>
              </div>
            </Box>
          )
        } else if(oneLocation.locationIsAway) {
          return <Box className={'location_box_root' + (isSec ? "  location_box_secondary" : "")} 
            style={{ width, height }} key={oneLocationIndex}>
            <div className='location_box_img'
              style={{
                backgroundImage: `url(${getUnLogedImage(oneLocation.logo, oneLocationIndex)})`,
                backgroundPosition: "center", backgroundSize: "cover"
              }}
              alt={`unloged_img_${oneLocationIndex}`}>
            </div>
            <div className='location_box_name' title="This location is on away screen">
              <PauseCircleOutlineIcon/>
              <label style={{ margin: '0 5px' }}>
                {oneLocation.locationname} <small>(Away)</small>
                {isSec &&
                  <sup style={{ color: "#f3c200" }} title='Secondary location'>&nbsp;2nd</sup>
                }
              </label>
            </div>
          </Box>
        } else {
          return (
            <Box className={'location_box_root' + (isSec ? "  location_box_secondary" : "")} 
              onContextMenu={(e)=>{
                e.preventDefault()
                onRightClick(e,locUsername)
              }}
              style={{ cursor: 'pointer', width, height }} key={oneLocationIndex}>
              <div className='location_box_img'
                style={{
                  display: "flex",
                  backgroundImage: `url(${getUnLogedImage(oneLocation.logo, oneLocationIndex)})`,
                  backgroundPosition: "center", backgroundSize: "cover"
                }}
                alt={`unloged_img_${oneLocationIndex}`}>
              </div>
              <Tooltip title='Waiting'>
                <div className='location_box_name'>
                  <HourglassEmptyOutlinedIcon />
                  <label style={{ margin: '0 5px' }}>
                    {oneLocation.locationname}<small> (waiting...)</small>
                    {isSec &&
                      <sup style={{ color: "#f3c200" }} title='Secondary location'>&nbsp;2nd</sup>
                    }
                  </label>
                </div>
              </Tooltip>
            </Box>
          )
        }
      })}
      <ContextMenu
        show={showContextMenu} 
        contextMenuRef={contextMenuRef} 
        handleCloseContextMenu={handleCloseContextMenu} 
        location={contextLocation}
        reloadLocation={(event,username)=>reloadLocation(event,username)}
        handleDeviceManagement={handleDeviceManagement}
        handleOpenMessagingBoard={handleOpenMessagingBoard}
        reload={contextMenuDisabled.reload}
        deviceManagement={contextMenuDisabled.deviceManagement}
        shareScreen={contextMenuDisabled.shareScreen}
        messagingBoard={contextMenuDisabled.messagingBoard}
        monitor={contextMenuDisabled.monitor}
        takePicture={contextMenuDisabled.takePicture}
        startMonitorCall={(username)=>startMonitorCall(username)}
        callState={callState}
        captureLocationCameraImage={captureLocationCameraImage}
        toggleShareScreen={toggleShareScreen}
        hangupHeldCall={contextMenuDisabled.hangupHeldCall}
        startHangupHeldCall={props.handleClickHoldLocation}
        />
    </>
  );
});

export default ListLocations;
