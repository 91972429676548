export default {
  /**
   * @returns Jitsi config to pass as the first argument to `window.JitsiMeetJS.init`
   * Usually this is the first config function that should be called.
   */
  getInit: () => {
    const isJaas = localStorage.getItem("isJaas");
    let result = {
      enableAnalyticsLogging: false,
    }
    if(isJaas == "1") {
      result = {
        ...result,
        analytics: {
          rtcstatsUseLegacy: false,
          rtcstatsPollInterval: 10000,
          rtcstatsSendSdp: false,
          rtcstatsEnabled: true,
          rtcstatsEndpoint: "wss://rtcstats-server-8x8.jitsi.net/"
        }
      } 
    } 

    try{
      const dynamicInitOptions = localStorage.getItem("jitsiInitOptions");
      if(dynamicInitOptions) {
        let dynamicInitOptionsObj = JSON.parse(dynamicInitOptions)
        removeUndefinedValues(dynamicInitOptionsObj)
        result = {...result, ...dynamicInitOptionsObj}
      }
    } catch (err) {
      console.error(84293, "jitsiInitOptions", err)
    }

    return result;
  },
  /**
   * @returns Jitsi config to pass as the third argument to `window.JitsiMeetJS.JitsiConnection`
   * Usually this is the second config function that should be called.
   */
  getConnection: () => {
    const isJaas = localStorage.getItem("isJaas");
    const jitsiMainDomain = localStorage.getItem("jitsiMainDomain")
    if (isJaas == "1") {
      const jaasTenant = localStorage.getItem("jaasAppID")
      const room = localStorage.getItem("lucas_therapies_roomname")
      return {
        hosts: {
          domain: jitsiMainDomain,
          muc: `conference.${jaasTenant}.${jitsiMainDomain}`,
        },
        serviceUrl: `wss://${jitsiMainDomain}/${jaasTenant}/xmpp-websocket?room=${room}`,
        websocketKeepAliveUrl: `https://${jitsiMainDomain}/${jaasTenant}/_unlock?room=${room}`,
      }
    } else {
      return {
        hosts: {
          domain: jitsiMainDomain || process.env.REACT_APP_JITSI_MAIN_DOMAIN,
          muc: 'conference.' + (jitsiMainDomain || process.env.REACT_APP_JITSI_MAIN_DOMAIN), // FIXME: use XEP-0030
        },
        serviceUrl: 'https://' + (localStorage.getItem("jitsiDnsName") || process.env.REACT_APP_JITSI_DNS_NAME) + '/http-bind', // FIXME: use xep-0156 for that
        clientNode: "https://jitsi.org/jitsimeet",
        useStunTurn: true
      }
    }
  },
  /**
   * @returns Jitsi config to pass as the second argument to `connection.current.initJitsiConference`
   * Usually this is the second config function that should be called.
   */
  getConference: async (finalJitsiUrlRef) => {
    const isJaas = localStorage.getItem("isJaas");
    let result = {};
    if (isJaas == "1") {
      try{
        const jitsiDefaultConfigRes = await fetch(`https://${finalJitsiUrlRef}/config.js`)
        let jitsiDefaultConfigText = await jitsiDefaultConfigRes.text();
        const deploymentInfo = jitsiDefaultConfigText.match(/deploymentInfo:\s*({[^}]*})/s);
        const analytics = jitsiDefaultConfigText.match(/analytics:\s*({[^}]*})/s);
        if(deploymentInfo) {
          result["deploymentInfo"] = JSON.parse(deploymentInfo[1]
            .replace(/([{,]\s*)(\w+)(\s*:)/g, '$1"$2"$3')
            .replace(/'/ig, '"')
            .replace(/,\s*}/, '}'))
        }
        if(analytics) {
          result["analytics"] = JSON.parse(analytics[1]
            .replace(/([{,]\s*)(\w+)(\s*:)/g, '$1"$2"$3')
            .replace(/'/ig, '"')
            .replace(/,\s*}/, '}')) 
          delete result?.analytics?.whiteListedEvents
        }
      } catch (err) {
        console.error(88915, `Calling ${finalJitsiUrlRef}/config.json failed`, err)
      }
    }
    result = {
      ...result,
      // enabling p2p will cause issues when setting lastN to 0 or 1
      p2p:{
        enabled: false
      },
      disableAudioLevels: true,
      videoQuality: {
        codecPreferenceOrder: [ 'VP9', 'VP8', 'H264']
      },
    };

    try{
      const dynamicConfOptions = localStorage.getItem("jitsiConfOptions");
      if(dynamicConfOptions) {
        let dynamicConfOptionsObj = JSON.parse(dynamicConfOptions)
        removeUndefinedValues(dynamicConfOptionsObj)
        result = {...result, ...dynamicConfOptionsObj}
      }
    } catch (err) {
      console.error(84295, "jitsiConfOptions", err)
    }

    return result;
  },
  setDynamicConfigs: ({jitsiInitOptions, jitsiConfOptions}) => {
    try{
      let jitsiInitOptionsSet = false;
      if(jitsiInitOptions) {
        try{
          if(Object.keys(jitsiInitOptions)[0]) {
            localStorage.setItem("jitsiInitOptions", JSON.stringify(jitsiInitOptions))
            jitsiInitOptionsSet = true;
          }
        } catch (err) {
          console.error(83290, "jitsiInitOptions", err)
        }
      }
      if(!jitsiInitOptionsSet) {
        localStorage.removeItem("jitsiInitOptions")
      }
  
      let jitsiConfOptionsSet = false;
      if(jitsiConfOptions) {
        try{
          if(Object.keys(jitsiConfOptions)[0]) {
            localStorage.setItem("jitsiConfOptions", JSON.stringify(jitsiConfOptions))
            jitsiConfOptionsSet = true;
          }
        } catch (err) {
          console.error(83295, "jitsiConfOptions", err)
        }
      }
      if(!jitsiConfOptionsSet) {
        localStorage.removeItem("jitsiConfOptions")
      }
    } catch (err) {
      console.error(83297, "jitsiInitOptions or jitsiConfOptions", err)
    }
  }
}

function removeUndefinedValues(obj) {
  if (!obj || typeof obj !== 'object') return obj;

  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      removeUndefinedValues(obj[key]);
    }
  }

  return obj;
}