import React, { useState, useEffect,useRef } from 'react';
import { styled } from '@mui/material/styles';
import Modal from 'react-bootstrap/Modal';
import {Button, Checkbox, IconButton, Tooltip, Grid} from "@mui/material";
import './modal-style.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FilerobotImageEditor, {
    TABS,
    TOOLS,
} from 'react-filerobot-image-editor';
import AdminService from '../../services/api';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import FolderIcon from '@mui/icons-material/Folder';
import HomeIcon from '@mui/icons-material/Home';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import  Download  from '@mui/icons-material/GetApp';
import { jsPDF } from "jspdf";
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyTwoTone';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import tokenService from '../../services/tokenService';
import SharedFiles from '../Reception/SharedFiles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Loading from '../Reception/Loading';

const StyledModal = styled(Modal)(({
  theme: {palette,mode}
}) => ({
  ['& .modal-content']:{
    backgroundColor:palette.background[mode] + " !important",
    color:palette.color[mode] + " !important"
   },
  [`& .gallery-home-page-Buttons`]: {
    color: palette.primary[mode] + " !important",
  },
  '& .gallery-home': {
    '& svg': {
      fontSize: "1.8rem",
    },
    // margin: "0px 0.5rem",
    padding: "6px 8px 7px 8px",
    color: palette.color[mode],
    borderRadius: "5px",
    lineHeight: 1,
    '& :hover': {
      cursor: "pointer",
      // boxShadow: "inset 0px 0px 0px 1px",
      color: palette.lightGray[mode]
    }
  },
  '& .gallery-text-btn': {
    color: palette.color[mode],
    marginInline: "0.5rem",
    textTransform: "capitalize",
    ':hover': {
      cursor: "pointer",
      color: palette.lightGray[mode]
    }
  }
}));

// was used in two places so variable to change in both places at once
const companySharedFiles = "Company Shared Files"
const API_URL = process.env.REACT_APP_API_URL;

var token;
export const GalleryModal = ({showModal, handleClose, defaultBatch}) => {
    const [content,setContent]=useState([])
    const [images,setImages]=useState([])
    const [selectedImages, setSelectedImages]=useState([])
    const [renderSelectedImages, setRenderSelectedImages]=useState([])
    const [selectedBatch, setSelectedBatch]=useState(null)
    const selecetedImageRef=useRef()
    selecetedImageRef.current=selectedImages
    const [editorImage,setEditorImage]=useState('')
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);
    const [batchesList,setBatchesList]=useState({})
    const batchesListRef=useRef()
    batchesListRef.current=batchesList
    const [bigImage, setBigImage] = useState(null);
    const [currentlyAllSelected, setCurrentlyAllSelected] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
    const [roadMap,setRoadMap]=useState('')
    const [showBtn,setShowBtn]=useState(false)
    const [loadingPdf,setLoadingPdf]=useState(false)

    useEffect(()=>{
        if(showModal) {
          getBatchesList()
          setBigImage(null)
          if(defaultBatch && defaultBatch == selectedBatch) {
            handleSelectedBatch(defaultBatch)
          } else if( defaultBatch) {
            handleSelectedBatch(defaultBatch)
          } else if(!selectedBatch) {
            onClickHome()
          }
          token = tokenService.get();
        }
    },[showModal])

    
    useEffect(()=>{
      if(defaultBatch && defaultBatch != selectedBatch) {
        getImagesOfBatch(defaultBatch)
        setSelectedBatch(defaultBatch)
      }
    },[defaultBatch])

    useEffect(() => {
      setSaveDisabled(selectedImages.length === 0)
      var list=[]
      if(!selectedImages || !selectedImages[0]) return setRenderSelectedImages([])
      selectedImages.forEach((img,index)=>{
        const url=`${API_URL}desktop/gallery/${img.filename}?token=${token}`
        if(!img.src)img.src=url
        list.push(
            <Grid className='gallery-item' key={index} style={{backgroundImage:`url(${img.src})`,width: '80%', height:"150px", margin:"5px",}} 
              onClick={() => handleOnclickImg(img)}>
                <div style={{paddingTop: "5px"}}>
                 <b className='gallery-number'>{index+1}</b>:
                </div>
                <div className='gallery-actions'>
                  <Tooltip title="unselect image">
                    <Checkbox
                      style={{color: "blue", padding: "7px"}}
                      checked={true}
                      onChange={(ev) => {handleSelectedImage(ev,img)}}
                      onClick={(ev) => {ev.stopPropagation()}}
                    />        
                  </Tooltip>    
                  <Tooltip title='Save image'>
                      <IconButton  className='gallery-download-icon' onClick={(ev)=>{ev.stopPropagation(); handleSaveImage(img)}} >
                        <Download />
                      </IconButton>
                  </Tooltip>
                  <Tooltip  title="Edit image">                            
                    <IconButton className='gallery-edit-icon'
                      onClick={(ev) => {ev.stopPropagation(); openImgEditor(img)}}> 
                      <EditOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </div>
            </Grid>
        )
    })
    setRenderSelectedImages(list)
    }, [selectedImages])

    useEffect(() => {
        var list=[]
        var n=0
        for(let img of images){
            let selectedIndex=selectedImages.findIndex((el)=>el.id===img.id)
            if(selectedIndex>=0){
              selectedIndex++;
              n++;
            }
            const url=`${API_URL}desktop/gallery/${img.filename}?token=${token}`
            if(!img.src)img.src=url
            list.push(
                <Grid className='gallery-item' key={img.id} style={{backgroundImage:`url(${img.src})`}} 
                  onClick={() => handleOnclickImg(img)}>
                    <div style={{paddingTop: "5px"}}>
                      {(selectedIndex>=0)?
                        <b className='gallery-number'>{selectedIndex}</b>:
                        <b>&nbsp;</b>
                      }
                    </div>
                    <div className='gallery-actions'>
                      <Tooltip title={(selectedIndex>=0)?"unselect image":"select image"}>
                        <Checkbox
                          style={{color: "blue", padding: "7px"}}
                          checked={(selectedIndex>=0)?true:false}
                          onChange={(ev) => {handleSelectedImage(ev,img)}}
                          onClick={(ev) => {ev.stopPropagation()}}
                        />
                      </Tooltip>
                      <Tooltip title='Save image' >
                        <IconButton className='gallery-download-icon'   onClick={(ev)=>{ev.stopPropagation(); handleSaveImage(img)}} >
                          <Download />
                        </IconButton>
                      </Tooltip>
                      {(selectedIndex>=0)?            
                        <Tooltip title='Edit Image'>            
                          <IconButton className='gallery-edit-icon'
                            onClick={(ev) => {ev.stopPropagation(); openImgEditor(img)}}> 
                            <EditOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title='Delete image'>
                          <IconButton centerRipple={true} className='gallery-remove-icon'
                            onClick={(ev) => {ev.stopPropagation(); removeImages([img])}}> 
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </div>
                </Grid>
            )
        }
        setShowBtn(true)
        setContent(list)
        setCurrentlyAllSelected(n === images.length)
    }, [selectedImages, images])

    const getBatchesList=async ()=>{
      try{
        setBigImage(null)
        let res = await AdminService.getBatchesList()
        if(res.data.code==0){
            const list=res.data.data
            var obj={}
            // fill with a fake data for "Company Shared Files"
            obj[companySharedFiles] = {dates: [], isPrimary: true}
            list.forEach(el => {
              const username = el.batchID.substring(0, el.batchID.lastIndexOf("-"))
              const date = el.batchID.substring(el.batchID.lastIndexOf("-")+1)
              if(!obj[username]) {
                obj[username]={dates: [], isPrimary: el.isPrimary}
              }
              obj[username].dates.push(date)
            });
            setBatchesList(obj)
        } else {
          toast.error(res.data.msg, {autoClose: 5 * 1000})
        }
      } catch (err) {
        console.error("1501:", err)
        toast.error(err.message, {autoClose: 10000})
      }
    }

    const onClickHome=async()=>{
      setShowBtn(false)
      contentListLocations() // call twice. Feels more responsive
      await getBatchesList()
      contentListLocations();
    }

    const contentListLocations=()=>{
      var obj=batchesListRef.current
      var locations=Object.keys(obj)
      var locationsContent=[]

      for(let loc of locations){
        const isSec = !obj[loc].isPrimary;
        if(isSec && !showSecondaryLocations) continue;
        locationsContent.push(<HomeFolder key={loc} name={loc} dates={obj[loc].dates} handleOnclick={()=>onClickLocation(loc)} />)
      }
      setContent(locationsContent)
      setRoadMap(
        <div className='d-flex align-items-center'>
        </div>
      )
    }


    const getImagesOfBatch = async(batchID)=>{
      try {
        let res=await AdminService.galleryList({batchID})
        if(res.data.code==0){
            const data=res.data.data
            setImages(data)
        }
      } catch (err) {
        console.error("1502   ",err)
        toast.error(err.message, {autoClose: 10000})

      }
    }

    const handleSelectedBatch=(batchID)=>{
      if(!batchID) return
      setSelectedBatch(batchID)
      setBigImage(null)
        // if(selecetedImageRef.current[0]){
        //     if(window.confirm('This action will reset any changes you have made. Are you sure?')){
        //         setSelectedImages([])
        //         setIsImgEditorShown(false)
        //         setEditorImage(null)
        //         getImagesOfBatch(batchID)
        //     }
        // }
         
        getImagesOfBatch(batchID)
        const batches=batchesListRef.current
        const loc = batchID.split('-').slice(0, -1).join('-');
        const date=new Date(Number(batchID.split('-').pop())).toLocaleString("en-ca", { hour12: false })

        setRoadMap(
          <div className='d-flex align-items-center'>
            <ArrowForwardIosIcon fontSize='small' />
            <b className='gallery-text-btn'  onClick={()=>onClickLocation(loc)}>{loc}</b>
            <ArrowForwardIosIcon fontSize='small' />
            <b className='gallery-text-btn' onClick={()=>handleSelectedBatch(batchID)}>{date}</b>
          </div>
        )
    }   

    const handleSelectedImage=(event,img)=>{
        if(event.target.checked){
            setSelectedImages([...selectedImages,img])
        }
        else {
            let imgs=[...selectedImages]
            imgs=imgs.filter((x)=>x.id!=img.id)
            setSelectedImages(imgs)
        }
        
    }

    const removeImages = async (imgs) => {
      if(window.confirm(`Delete ${imgs.length} files permanently, Are you sure?`)){
        try{
          const filenames = imgs.map(item => item.filename)
          const loc = imgs[0].batchID.split('-')[0];
          let res = await AdminService.galleryRemove({filenames})
          if(res.data.code==0){
            let allImages = [...images]
            for(let img of imgs) {
              allImages = allImages.filter((x) => x.id != img.id)
            }
            setImages(allImages)

            let allSelected = [...selectedImages]
            for(let img of imgs) {
              allSelected = allSelected.filter((x) => x.id != img.id)
            }
            setSelectedImages(allSelected)

            const batchStillExists = allImages.find(item => item.batchID === imgs[0].batchID)
            if(!batchStillExists) {
              getBatchesList()
            }
            if (allImages.length === 0) {
              onClickLocation(loc);
            }
          }
        } catch (err) {
          console.error("1503   ",err)
          toast.error(err.message, {autoClose: 10000})
        }
      }
    }

    const onSaveFilerobotImageEditor=(editedImageObject, designState)=>{
        const base64=editedImageObject.imageBase64
        var all=[...images]
        var imgs=[...selectedImages]
        imgs=imgs.map((img)=>{
            if(img.id===editorImage.id){
                img.src=base64
            }
            return img
        })
        setSelectedImages(imgs)
        all=all.map((img)=>{
            if(img.id===editorImage.id){
                img.src=base64
            }
            return img
        })
        setImages(all)
        setIsImgEditorShown(false);
        setEditorImage(null)
    }
    const closeImgEditor = () => {
        setIsImgEditorShown(false);
        setEditorImage(null)
    };
    const openImgEditor = (img) => {
        setEditorImage(img)
        setIsImgEditorShown(true);
    };

    const addCenterImage = async (doc, imgData) => {
      return new Promise((resolve) => {
        var img = new Image();
        img.src = imgData;
        img.onload = function() {
          const width = doc.internal.pageSize.getWidth();
          const height = (img.height * width) / img.width; // Maintain aspect ratio
          let x=0, y=0;
          // if(width < img.width) {
          //   x = (width - img.width) / 2
          // }
          if(height < doc.internal.pageSize.getHeight()) {
            y = (doc.internal.pageSize.getHeight() - height) / 2 // make it center
          }
          doc.addImage(imgData, x, y, width, height);
          resolve();
        };
      })
    }

    const handleSaveFiles=async ()=>{
      try{
        setLoadingPdf(true)
        setSaveDisabled(true)
        const files=[]
        for(let f of selectedImages){
          var data=f.src
          if(data.startsWith(API_URL)){
            const res = await getBase64Image(data);
            files.push(res)
          } else {
            files.push(f.src)
          }
        }
        if(files.length === 0) {
          toast.error("Please select at least one image first.")
          setLoadingPdf(false)
          setSaveDisabled(false)
          return;
        }
        if(window.electron){
          const saveRes = await window.electron.saveFiles(files)
          if(saveRes.code !== 0) {
            toast.error(saveRes.msg, {autoClose: 10000})
          }
        } else {
          const doc = new jsPDF();
          for(let i in files) {
            await addCenterImage(doc, files[i])
            if(i != files.length-1) {
              doc.addPage(); 
            }
          }
          window.open(doc.output('bloburl'), 'Download')
        }

        setLoadingPdf(false)
        setSaveDisabled(false)
        // After save, back to first page (list locations)
        setSelectedBatch(null)
        setSelectedImages([])
        setIsImgEditorShown(false)
        setEditorImage(null)
        // handleClose()
        toast.success("File saved successfully!")
      } catch (err) {
        setLoadingPdf(false)
        setSaveDisabled(false)
        console.error("1505   ",err)
        toast.error(err.message, {autoClose: 10000})
      }
    }
    const handleSaveImage=async (img)=>{
      try{
        setShowLoading(true)
        const files=[]
        var data=img.src
        if(data.startsWith(API_URL)){
          const res = await getBase64Image(data);
          files.push(res)
        } else {
          files.push(data)
        }
        if(files.length === 0) {
          toast.error("Please select at least one image first.")
          setShowLoading(false)
          return;
        }
        if(window.electron){
          const saveRes = await window.electron.saveImage(files)
          if(saveRes.code !== 0) {
            toast.error(saveRes.msg, {autoClose: 10000})
          }
        } else {
          for (var i = 0; i < files.length; i++) {
            let imageData = files[i];
            let ext = "";
            let dataURI = imageData.split(",").pop()
            if(dataURI.startsWith("/9j/")) {
              ext = ".jpg"
            } else if (dataURI.startsWith("iVBORw0KGgo")) {
              ext = ".png"
            } else if (dataURI.startsWith("R0lGO")) {
              ext = ".gif"
            } else if (dataURI.startsWith("U")) {
              ext = ".webp"
            }
            let link = document.createElement("a");
            link.href = imageData;
            link.download = `VirtualSally_image_${Date.now()}${ext}`;
            link.click();
            // clean up "a" element & remove ObjectURL
            // document.body.removeChild(link);
          }
        }
        setShowLoading(false)
        setIsImgEditorShown(false)
        setEditorImage(null)
        toast.success("File saved successfully!")
      } catch (err) {
        setShowLoading(false)
        console.error("1506", err)
        toast.error(err.message, {autoClose: 10000})
      }
    }
    const getBase64Image=async(url) => {
      try {
          const response = await fetch(url);
          const blob = await response.blob();
          const reader = new FileReader();
          await new Promise((resolve, reject) => {
            reader.onload = resolve;
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });
          return reader.result//.replace(/^data:.+;base64,/, '')
        } catch (error) {
          console.error("1507", error)
        }
    }

    const selectAll = () => {
      var allImages=images
      allImages=allImages.filter((a)=>!selectedImages.find((b)=>a.id ==b.id))
        
      setSelectedImages([...selectedImages , ...allImages])
    }

    const deSelectAll = () => {
      var allSelected=selectedImages
      allSelected=allSelected.filter((a)=>!images.find((b)=>a.id==b.id))
      setSelectedImages(allSelected)
    }

    const Folder=({name,handleOnclick})=>{
      return (
        <Button className='m-2' style={{width:'20%'}} color="primary" onClick={handleOnclick} startIcon={<FolderIcon/>} >
          {name}
        </Button>
      )
    }

    const HomeFolder = ({name, handleOnclick, dates}) => {
      // last modified is the date of the last item in dates array
      let lastModified = "Unknown", count = "Unknown";
      if(Array.isArray(dates) && dates[0] && Number(dates[dates.length-1])  || name===companySharedFiles) {
          lastModified = new Intl.DateTimeFormat('en-us', {
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false
          }).format(dates[dates.length-1])
        count = dates.length
      } else return
      return (
        <Button className='gallery-home-page-Buttons my-1 p-1 justify-content-start text-capitalize col-12 col-md-6 col-lg-4 col-xl-3'
           title={name} color="primary" onClick={handleOnclick}>
          <div style={{position: "relative"}}>
            <FileCopyTwoToneIcon style={{fontSize: "4rem"}}/>
            {name === companySharedFiles && 
              <CloudDownloadIcon style={{fontSize: "1.2rem", position: "absolute", top: "0px", right: "0px"}}/>
            }
          </div>
          <div className='ml-2 d-flex flex-column align-items-start justify-content-start' style={{height: "60px"}} >
            <span><b className='text-truncate' style={{maxWidth: "180px"}}>{name}</b></span>
            <span style={{fontSize: "13px", lineHeight: 1.4}}>Modified: {lastModified}</span>
            <span style={{fontSize: "13px", lineHeight: 1.4}}>Count: {count}</span>
          </div>
        </Button>
      )
    }

    const contentListBatches=(loc)=>{
      if(loc === companySharedFiles) {
        setContent(<SharedFiles handleOnclickImg={handleOnclickImg}/>)
      } else {
        const folders=[]
        var batches =batchesListRef.current
        const list=batches[loc]?.dates
        setBigImage(null)
        if(list && Array.isArray(list) && list[0]){
          list.forEach((d)=>{
            let batchID=loc+'-'+d
            let name=new Date(Number(d)).toLocaleString("en-us", { hour12: false })
            folders.push(
              <Folder key={batchID} name={name} handleOnclick={()=>handleSelectedBatch(batchID)} />
            )
          })
        }
        setContent(folders)
      }
      setRoadMap(
          <div className='d-flex align-items-center'>
            <ArrowForwardIosIcon fontSize='small' />
            <b className='gallery-text-btn'  onClick={()=>onClickLocation(loc)}>{loc}</b>
          </div>
      )
    }

    const onClickLocation=async(loc)=>{
      setShowBtn(false)
      contentListBatches(loc) // call twice. Feels more responsive
      await getBatchesList()
      contentListBatches(loc)
    }

    const handleOnclickImg = (img)=>{
      handleRoadMap(img, true)
      setBigImage(img)
    }

    const handleClosebigImg=()=>{
      handleRoadMap(bigImage, false)
      setBigImage(null)
    }

    const handleRoadMap = (img, showImgID) => {
      const batchID=img.batchID
      if(batchID === companySharedFiles) {
        setRoadMap(
          <div className='d-flex align-items-center'>
            <ArrowForwardIosIcon fontSize='small'/>
            <>
              <b className='gallery-text-btn' onClick={()=>onClickLocation(companySharedFiles)}>{companySharedFiles}</b>
              <ArrowForwardIosIcon fontSize='small'/>
            </>
            {showImgID && 
              <b className='gallery-text-btn'>{img.id}</b>
            }
          </div>
        )
      } else {
        let loc, date;
        if(batchID) {
          loc = batchID.split('-').slice(0, -1).join('-');
          if(batchID.split('-').pop()) {
            date = new Date(Number(batchID.split('-').pop())).toLocaleString("en-ca", { hour12: false })
          }
        }
        setRoadMap(
          <div className='d-flex align-items-center'>
            <ArrowForwardIosIcon fontSize='small' />
            {loc && 
              <>
                <b className='gallery-text-btn'  onClick={()=>onClickLocation(loc)}>{loc}</b>
                <ArrowForwardIosIcon fontSize='small' />
              </>
            }
            {date &&
              <>              
                <b className='gallery-text-btn' onClick={()=>handleSelectedBatch(batchID)}>{date}</b>
                <ArrowForwardIosIcon fontSize='small' />
              </>
            }
            {showImgID && 
              <b className='gallery-text-btn' >{img.id}</b>
            }
          </div>
        )
      }

    }
        
    return (
      <>
      <Loading show={showLoading}/>
      { bigImage ? 
        <StyledModal  show={true} size="xl" onHide={handleClosebigImg} >
          <Modal.Body style={{position: "relative", padding: "0px"}}>
              <div className='gallery-header'>
                <>
                  <span className='gallery-home'>
                    <HomeIcon onClick={onClickHome} /> 
                  </span>
                  {roadMap}
                </>
                <span className='gallery-home'>
                  <CloseIcon onClick={handleClosebigImg} />
                </span>
              </div>
            <div style={{width: "100%", height: "85vh", overflow: "auto"}}>
              {bigImage.type === "pdf" 
                ? <object data={bigImage.src} style={{width: "100%", height: "100%"}}></object>
                : <img className='img-fluid' src={bigImage.src}/>
              }
            </div>
          </Modal.Body>
        </StyledModal> 
        :
        <StyledModal  show={showModal} size="xl" onHide={handleClose} >
          <Modal.Body style={{padding: "0.5rem"}}>
            {
              isImgEditorShown ||
              <div className='gallery-header'>
                <div className='d-flex flex-row'>
                  <span className='gallery-home'>
                    <HomeIcon onClick={onClickHome} /> 
                  </span>
                  {roadMap}
                </div>
                <span className='gallery-home'>
                  <CloseIcon onClick={handleClose}/>
                </span>
              </div>
            }

              <Grid  color='secondary' container  direction='row' 
                style={{height:"85vh", overflowY: "hide"}}>
              {(isImgEditorShown)?
                <FilerobotImageEditor
                    source={editorImage?.src}
                    onSave={onSaveFilerobotImageEditor}
                    onClose={closeImgEditor}
                    annotationsCommon={{
                        fill: '#ff0000',
                        shadowColor:"#ff0000"
                    }}
                    
                    Text={{ text: 'Filerobot...' }}
                    Rotate={{ angle: 90, componentType: 'buttons' }}
                    Crop={{
                        presetsItems: [
                        {
                            titleKey: 'classicTv',
                            descriptionKey: '4:3',
                            ratio: 4 / 3,
                            // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
                        },
                        {
                            titleKey: 'cinemascope',
                            descriptionKey: '21:9',
                            ratio: 21 / 9,
                            // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
                        },
                        ]
                    }}
                    tabsIds={[TABS.ADJUST,TABS.FILTERS]} // or {['Adjust', 'Annotate', 'Watermark']}
                    defaultTabId={TABS.ADJUST} // or 'Annotate'
                    defaultToolId={TOOLS.ROTATE} // or 'Text'
                    defaultSavedImageType={'jpeg'}
                    onBeforeSave={(imageFileInfo)=>{return false}}
                    />:
                    <>
                      <div className={(selectedImages && selectedImages[0])?'row':""} style={{width: "100%", height: "94%", overflowY:"auto", marginInline:'1%'}} >
                        {(selectedImages && selectedImages[0]?
                          <>
                            <div style={{width: "85%", height: "94%", overflowY:"auto"}}>
                              {content}
                            </div>
                            <div style={{width:"15%",height: "94%", overflowY:"auto",overflowX:'hidden',borderLeft:"1px solid gray"}}>
                              {renderSelectedImages}
                            </div>
                          </>:content
                        )}
                      </div>
                      <div className='d-flex justify-content-between align-items-center pb-2 px-1' style={{width: "100%"}}>
                        <div>
                          {selectedImages.length > 0 ? 
                            <Button 
                              variant="contained" 
                              color="error" 
                              startIcon={<DeleteIcon />}
                              className='mr-2 text-capitalize' onClick={() => removeImages(selectedImages)}>
                              Delete
                            </Button>
                            // <button className='btn btn-danger rounded' onClick={() => removeImages(selectedImages)}>
                            //   Delete
                            // </button> 
                            : ""
                          }
                          {showBtn ?
                            (currentlyAllSelected ? 
                              <Button className='text-capitalize' variant="contained" color="secondary" 
                                startIcon={<CheckBoxIcon/>} onClick={deSelectAll}>Deselect All</Button> :
                              <Button className='text-capitalize' variant="contained" color="secondary" 
                                startIcon={<CheckBoxOutlineBlankIcon/>} onClick={selectAll}>Select All</Button>
                            )
                            :''
                          }
                        </div>
                        <div>
                          <Button 
                            variant="contained" 
                            color="secondary" 
                            disabled={saveDisabled} 
                            onClick={handleSaveFiles}
                            className='text-capitalize'
                            startIcon={(loadingPdf)?<ReactLoading type='spokes'  color={"black"}  style={{width:"24px",height:"24px",display:"flex"}}/> :<Download/>}>
                              Save PDF
                          </Button>
                        </div>
                      </div>                               
                    </>
                  }
              </Grid>
          </Modal.Body>
        </StyledModal>
      }
      </>
    )
} 