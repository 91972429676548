import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { styled } from "@mui/system";

const StyledButton = styled(Button)(({ theme: { palette, mode } }) => ({
  position: "relative",
  width: "20vh",
  height: "20vh",
  border: "2px solid " + palette.primary['main'],
  color: palette.primary['main'],
  fontWeight: "600",
  borderRadius: "50%",
  overflow: "hidden",
  "&:hover": {
    borderColor: palette.primary['main'],
  },
  "*": {
    fontSize: "10vh",
  },
  outline: "none",
  boxShadow: "none",
  "&:focus": {
    outline: "none",
    boxShadow: "none",
  },
}));

const CircularProgress = styled("div")(({ progress }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  borderRadius: "50px",
  zIndex: 0,
  background: `conic-gradient(#80008044 ${progress}%, transparent 0%)`,
  // transition: "background 0.1s linear",
  pointerEvents: "none",
}));

const HoldButton2 = ({onHoldComplete}) => {
  const [progress, setProgress] = useState(0);
  const timerRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchend", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, [])

  const handleMouseDown = () => {
    let progressCount = 0;
    timerRef.current = setInterval(() => {
      progressCount += 10;
      setProgress((progressCount / 2000) * 100); 
      if (progressCount >= 2000) {
        clearInterval(timerRef.current);
        onHoldComplete();
      }
    }, 10);
  };

  const handleMouseUp = () => {
    clearInterval(timerRef.current);
    setProgress(0); // Reset progress if released early
  };

  return (
    <div className="hold-button2">
      <StyledButton
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleMouseUp}
        variant="outlined"
        disableRipple
      >
        <TouchAppIcon/>
        <CircularProgress progress={progress} />
      </StyledButton>
    </div>
  );
};

export default HoldButton2;
