import React, { Component } from 'react';
import './style.css';
import Constant from '../../constants/constant';
import Str from '../../constants/string';
import UserColor from '../../constants/usercolor';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MessageIcon from '@mui/icons-material/Message';
import { ThemeToggleContext } from '../../context/theme';
import {ButtonBase,Grid,Tooltip, useTheme} from "@mui/material";
import { useEffect,useState } from 'react';
import {useSelector } from 'react-redux';

export default ({username, status, otherpartid, handleOpenChat, displayName})=>{
    const theme = useTheme();
    const [color,setColor]=useState()
    const [finalRecName, setFinalRecName] = useState(displayName || username)
    const unRead = useSelector((state) => state.messages.unRead[username])

    useEffect(()=>{
      if (status === Constant.AWAY) {
        setColor(theme.palette.warning[theme.palette.mode])
      } else if (status === Constant.JOIN) {
        setColor(theme.palette.success[theme.palette.mode])
      } else if (status === Constant.CALL) {
        setColor(theme.palette.pink[theme.palette.mode])
      } else {
        setColor(theme.palette.error[theme.palette.mode])
      }
    },[status])

    useEffect(() => {
      setFinalRecName(displayName || username)
    }, [displayName, username])

    const handleOnClickChat=()=> {
      handleOpenChat(username,displayName)
    }

    return (
      <div className='remote_reception_area'>
        <Tooltip className='d-flex' style={{width: "80%"}}
          title={otherpartid ? <><i>{finalRecName}</i>, in call with <i>{otherpartid}</i></> : <i>{finalRecName}</i>}>
          <FiberManualRecordRoundedIcon className='mr-1' style={{color}} fontSize='small'/>
          <label className='d-flex align-items-center reception_name text-truncate'>
              {finalRecName} 
              <span style={{fontSize: "14px"}}>&nbsp;
              {otherpartid ? 
                  <span>(<PhoneInTalkIcon fontSize='inherit'/>&nbsp;{otherpartid})</span>: 
                  ""
              }
              </span>
          </label>
        </Tooltip>
        <ThemeToggleContext.Consumer>{({mode})=>{
            return (
                <Grid item  className='remote-user-view-grid'>
                    <Tooltip title={"Chat with " + (finalRecName)} placement='left'>
                      <ButtonBase className='btn-chat' onClick={handleOnClickChat}>
                        <MessageIcon style={{color:(mode=='light'?'rgba(0, 0, 0, 0.87)':'#fff')}} />
                      </ButtonBase>
                    </Tooltip>
                    <div className='remote-user-view-number-unread'>
                        <small>{(unRead && unRead>0)?unRead :''}</small>
                    </div>
                </Grid>

            )
        }}
        </ThemeToggleContext.Consumer>
    </div>
    );
}