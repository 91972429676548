import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import NoWifiIcon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4Rounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import './topAlert.css';

// priority: network, cpu, memory
export default function TopAlert({ connectionQuality, cb }) {
  const [cpuPercent, setCpuPercent] = useState(-1)
  const [memPercent, setMemPercent] = useState(-1)
  const [showCpu, setShowCpu] = useState(false)
  const [showMem, setShowMem] = useState(false)
  const [showCon, setShowCon] = useState(false)
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (window.electron) {
      window.electron.showResourcesAlert((event, data) => {
        setCpuPercent(data.avg1Min)
        setMemPercent(data.memPercent)
      });
    }
  }, [])

  useEffect(() => {
    if(connectionQuality && connectionQuality.connectionQuality > 0) { // its default value is 0
      if (connectionQuality.connectionQuality < 30) {
        setShowCon(true)
      } else if (connectionQuality.connectionQuality > 50) {
        setShowCon(false)
      }
    }

    if (cpuPercent > 90) {
      setShowCpu(true)
    } else if (cpuPercent < 80) {
      setShowCpu(false)
    }

    if (memPercent > 90) {
      setShowMem(true)
    } else if (memPercent < 80) {
      setShowMem(false)
    }

  }, [cpuPercent, memPercent, connectionQuality]);

  useEffect(() => {
    let tmpShow = showCon || showCpu || showMem
    setShow(tmpShow)
    cb(tmpShow)
  }, [showCon, showCpu, showMem])

  return (
    show && 
    <>
      <span className="reception_alert_badge top-alert-short">
        {showCon ?
          <><b>Network issue</b>. VS might not work well.</>
        : showCpu ?
          <>High <b>CPU</b> load. VS might slow down.</>
        : showMem ?
          <>Low <b>memory</b>. VS performance may drop.</>
        : ""}
      </span>
      <span className="reception_alert_badge top-alert-long">
        {showCon ?
          <><NoWifiIcon className='pb-1 pr-1' />The <b>network connection</b> seems unstable. VS might not work properly.</>
        : showCpu ?
          <><WarningRoundedIcon className='pb-1 pr-1' />Your system's <b>CPU</b> appears to be under heavy load, which may affect VS performance.</>
        : showMem ?
          <><WarningRoundedIcon className='pb-1 pr-1' />Your system's <b>memory</b> appears to be very low, which may affect VS performance.</>
        : ""}
      </span>
    </>
    
  );
}

