import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Button, colors} from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import { styled } from '@mui/material/styles';

const ModalStyled = styled(Modal)(({
  theme: {palette, mode}
}) => ({
  '& .modal-content': {
    backgroundColor:palette.background[mode] + " !important",
    color:palette.color[mode] + " !important"
  },
  "& .modal-btn": {
    padding: "10px 19px",
    borderRadius: "40px",
    outline: "0px",
    fontWeight: "bolder",
    '& span': {
      paddingTop: "1px"
    }
  },
  "& .modal-btn-primary": {
    color: "#ffffff",
    backgroundColor: colors.indigo[400],
    '&:hover': {
      backgroundColor: colors.indigo[600],
    },
  },
  "& .modal-btn-default": {
    color: "#ffffff",
    backgroundColor: colors.grey[500],
    '&:hover': {
      backgroundColor: colors.grey[700],
    },
  },
  zIndex: 2002, // so it goes on top of the loading. Right now loading has zIndex of 2001 and 2002
}))

export const Confirm = forwardRef((props, ref) => {
  const [title, setTitle] = useState("");
  const [msg, setMsg] = useState("");
  // const [buttons, setButtons] = useState([]);
  const [inputButtons, setInputButtons] = useState([]);
  const [resolveFn, setResolveFn] = useState(null);
  const [open, setOpen] = useState(false);
  const [size, setSize]=useState('lg')
  useImperativeHandle(ref, () => ({
    show(inputTitle, inputMsg, inputButtons, size) {
      setTitle(inputTitle)
      setMsg(inputMsg)
      setOpen(true)
      setSize(size)
      setInputButtons(inputButtons)
      // let buttons = [];
      // const done = new Promise((resolve, reject) => {
      //   for(let inputButton of inputButtons) {
      //     buttons.push(

      //     )
      //   }
      // })
      // setButtons(buttons)
      return new Promise((resolve) => {
        setResolveFn(() => resolve)
      });
    },
    hide() {
      setOpen(false)
    }
  }));

  const handleButtonClicked = (value) => {
    if(resolveFn) {
      resolveFn(value)
      setResolveFn(null)
    }
  }
  
  return (
    <div style={{zIndex: 2002}}>
      <ModalStyled show={open} size={size} fullWidth={true} centered backdrop="static" keyboard={false}>
        <Modal.Header>
          {title}
        </Modal.Header>
        <Modal.Body>
          {msg}
        </Modal.Body>
        <Modal.Footer>
          {inputButtons.map((inputButton) => 
            <Button 
              className={"modal-btn " + (inputButton.color ? ('modal-btn-'+inputButton.color) : '')}
              onClick={() => {
                handleButtonClicked(inputButton.value);
                if(inputButton.close) {
                  setOpen(false)
                }
              }} 
            >
              {inputButton.text}
            </Button>
          )}
        </Modal.Footer>
      </ModalStyled>
    </div>
  );
})