import React, { useEffect, useState } from "react";
import * as $ from "jquery";
import "./remotelocationvideoview.css";
import Constant from "../../constants/constant";
import Str from "../../constants/string";
import UserColor from "../../constants/usercolor";
import CallIcon from "../../assets/images/hold_on_icon.png";
import { Tooltip } from "@mui/material";
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const RemoteLocationVideoView = (props) => {
  const [track, setTrack] = useState(props.track);
  const [othertrack, setOthertrack] = useState(props.othertrack);
  const [videoTagID, setVideoTagID] = useState(props.video_tag_id);
  const [locationName, setLocationName] = useState(props.locationName);
  const [calltag, setCalltag] = useState(props.calltag);
  const [tagColor, setTagColor] = useState(props.calltagColor);
  const [status, setStatus] = useState(props.status);
  const [width, setWidth] = useState(props.width || "100%");
  const [height, setHeight] = useState(props.height || "100%");
  const [tracksMutedContent, setTracksMutedContent] = useState([]);
  const [connectionQualityColor, setConnectionQualityColor] = useState("white");
  const [connectionQualityText, setConnectionQualityText] = useState("Loading...");
  const [connectionQuality, setConnectionQuality] = useState(0);
  const [connectionQualityDesc, setConnectionQualityDesc] = useState("");

  const remoteStats = useSelector((state) => state.remoteStats);

  useEffect(() => {
    setTrack(props.track);
    setVideoTagID(props.video_tag_id);
  }, [props.track, props.video_tag_id]);

  useEffect(() => {
    if ( track && videoTagID ) {
      track.attach($(`#${videoTagID}`)[0]);
    }
  }, [track, videoTagID])

  useEffect(() => {
    const tracksMutedContentTmp = [];
    if (props.tracksMuted) {
      if (props.tracksMuted[props.id][Str.STR_VIDEO]) {
        tracksMutedContentTmp.push(<VideocamOffOutlinedIcon />);
      }
      if (props.tracksMuted[props.id][Str.STR_AUDIO]) {
        tracksMutedContentTmp.push(<MicOffOutlinedIcon />);
      }
    }
    setTracksMutedContent(tracksMutedContentTmp);
  }, [props.tracksMuted, props.id]);

  useEffect(() => {
    let qualityTmp = 0;
    if (remoteStats[props.id]) {
      qualityTmp = parseInt(remoteStats[props.id].connectionQuality);

      let output = ""
      const part = remoteStats[props.id]
      if(part) {
        let keys = Object.keys(part).sort();
        for(let i of keys) {
          if(part[i]) {
            output += `-${i}: ${JSON.stringify(part[i])?.replace(/\{|\"|\}/ig, '').replace(/\,/ig, ', ')}\n`
          }
        }
        setConnectionQualityDesc(output)
      }
    }
    let colorTmp, textTmp;
    if (!qualityTmp || qualityTmp === 0) {
      colorTmp = "white";
      textTmp = "Loading...";
    } else if (qualityTmp < 30) {
      colorTmp = "#D91E18";
      textTmp = "Very poor";
    } else if (qualityTmp < 60) {
      colorTmp = "#f3c200";
      textTmp = "Poor";
    } else if (qualityTmp < 90) {
      colorTmp = "#26C281";
      textTmp = "Good";
    } else {
      colorTmp = "#26C281";
      textTmp = "Excellent";
    }
    setConnectionQuality(qualityTmp);
    setConnectionQualityColor(colorTmp);
    setConnectionQualityText(textTmp);
  }, [remoteStats, props.id]);

  useEffect(() => {
    setStatus(props.status);
    setOthertrack(props.othertrack);
  }, [props.status, props.othertrack]);

  useEffect(() => {
    setLocationName(props.locationName);
  }, [props.locationName]);

  useEffect(() => {
    if(props.callBy)setCalltag(props.callBy)
    else setCalltag(props.calltag);
    let tagColorTmp = props.calltagColor;
    if(!tagColorTmp){
      switch (props.calltag) {
        case Str.STR_NORMAL:
          tagColorTmp = UserColor.GREEN_COLOR;
          break;
        case Str.STR_NEW_PATIENT:
          tagColorTmp = UserColor.YELLOW_COLOR;
          break;
        case Str.STR_NO_APPOINTMENT:
          tagColorTmp = UserColor.RED_COLOR;
          break;
        case Str.STR_SELF_CHECK:
          tagColorTmp = UserColor.BLUE_COLOR;
          break;
        case Str.STR_INITIAL_EVAL:
          tagColorTmp = UserColor.YELLOW_COLOR;
          break;
        case Str.STR_CHECK_IN:
          tagColorTmp = UserColor.BLUE_COLOR;
        case Str.KIOSK:
          tagColorTmp = UserColor.YELLOW_COLOR;
          break;
        default:
          tagColorTmp = UserColor.GREEN_COLOR;;
      }
    }

    setTagColor(tagColorTmp);
  }, [props.calltag , props.callBy,props.calltagColor]);

  useEffect(() => {
    if(!isNaN(props.width)) {
      setWidth(props.width);
    }
    if(!isNaN(props.height)) {
      setHeight(props.height);
    } 
  }, [props.width, props.height]);

  useEffect(() => {
    return () => {
      if (track) {
        track.detach($(`#${videoTagID}`)[0]);
      }
    };
  }, []);

  const handleClickItem = () => {
    const myJitsiUserId = localStorage.getItem("myJitsiUserId")
    if (track) {
      if (!props.inCall) {
        props.handleClickLocationItem(props.id);
        return true;
      } else if ( myJitsiUserId && othertrack && othertrack.getParticipantId() === myJitsiUserId) {
        props.handleClickLocationItem(props.id);
        return true;
      } 
    }
    return false;
  };

  const onSayHiClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const ableToClick = handleClickItem()
    if(!ableToClick) {
      toast.warn("Unable to call this location")
      return
    }
    setTimeout(() => {
      props.handleLocationCall();
    }, 500);
  };

  const getTimeString = (time) => {
    let result = "";
    const hours = Math.floor((time / 60 / 60) % 24);
    const minutes = Math.floor((time / 60) % 60);
    const seconds = time % 60;
    if (hours === 0 && minutes === 0 && seconds === 0) {
      return "0 s";
    }
    if (hours > 0) {
      result += hours + " h ";
    }
    if (minutes > 0) {
      result += minutes + " m ";
    }
    if (seconds > 0) {
      result += seconds + " s";
    }
    return result;
  };

  return (
    <React.Fragment>
      <div className="patient_area" onClick={handleClickItem}
      onContextMenu={(e)=>{
        e.preventDefault()
        props.rightClick(e,props.username)
      }}
      >
        <div className={othertrack === null && status === Constant.INCOMING ? "camera_area animate" : "camera_area stop"}>
          <video
            className="patient_img"
            autoPlay="1"
            id={videoTagID}
            playsInline
            style={{ width: width, height: height, cursor: status === Constant.CALL ? "not-allowed" : "pointer" }}
          />
          {(status === Constant.INCOMING && !othertrack) ?
            <span
              className="pl-0 py-1 d-flex ontop-banners" 
              style={{ color: "#fff", cursor: "pointer", right: 0 }}
              onClick={onSayHiClick}
            >
              <label style={{ margin: "0 5px", cursor: "pointer", fontWeight: "bold" }}>
                Say Hi
              </label>
            </span>
          : ""}
          <div className={status === Constant.HOLD ? "over_area show" : "over_area hide"}>
            <div className="over_container">
              <div className="over_opacity_area"></div>
              <img
                className="hold_call_img"
                src={CallIcon}
                alt="hold_call_img"
              />
              <div className="hold_info_area">
                <div className="hold_time_area">
                  <label className="hold_lab" id="hold_time">
                    {getTimeString(props.holdTimer)}
                  </label>
                </div>
                <label className="hold_lab">On hold</label>
              </div>
            </div>
          </div>
          {calltag && status !== Constant.HOLD &&
            <div className="calltag_div" style={{ backgroundColor: tagColor }}>
              <label
                className="calltag_lab"
                style={{color: calltag === Str.STR_NORMAL ? "black" : "white"}}
              >
                {calltag}
              </label>
            </div>
          }
        </div>
        {/* <label className='patient_name'>{locationName}</label> */}
        <label className="location_box_name d-flex justify-content-between m-0 px-2">
          <span>
            {tracksMutedContent} {locationName}
          </span>
          <Tooltip title={`${locationName} chosen language`}>
            <span>
              {props.currentLangCode ? props.currentLangCode.toUpperCase() : ""}
            </span>
          </Tooltip>
        </label>
        <Tooltip style={{backgroundColor: "#090d05bb"}}
          title={<pre style={{width: "300px", textWrap: "auto", color: "#c9cdd5", fontSize: "14px", margin: "0px", padding: "5px"}}>
            {connectionQualityDesc}
          </pre>}
        >
          <span className="pl-0 py-1 d-flex ontop-banners" style={{ color: connectionQualityColor, left: 0 }}>
            <SignalCellularAltIcon />
            <label style={{ margin: "0 5px" }}>
              {connectionQualityText}
            </label>
          </span>
        </Tooltip>
      </div>
    </React.Fragment>
  );
};

export default RemoteLocationVideoView;
