export async function loadJitsiLib() {
  return new Promise((resolve) => {
    if(window.JitsiMeetJS){
      console.error("window.JitsiMeetJS exists but loadJitsiLib is called. Ignoring it!")
      resolve()
      return
    }
    const jitsiLibUrl = localStorage.getItem("jitsiLibUrl")
    if(!jitsiLibUrl) {
      loadFallback(resolve);
      return;
    }
    const primaryScript = document.createElement('script');
    const src = process.env.REACT_APP_UPLOAD_URL + jitsiLibUrl
    primaryScript.src = src;
    primaryScript.async = false;
    primaryScript.onerror = (err) => {
      console.error("Failed to load jitsi library dynamically", src, err)
      loadFallback(resolve);
      return
    };
    primaryScript.onload = () => {
      resolve()
    };
  
    document.body.appendChild(primaryScript);
  })
}

async function loadFallback(resolve) {
  const fallbackScript = document.createElement('script');
  fallbackScript.src = `${process.env.PUBLIC_URL}/lib-jitsi-meet/index.min.js`;
  fallbackScript.onload = () => {
    resolve()
  };

  document.body.appendChild(fallbackScript);
}