import React from "react";
import ReactLoading from 'react-loading';
export default function Loading ({show}) {
  return (
    show ? 
      <div>
        <div style={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: "#000",
          opacity: 0.75,
          zIndex: 2000,
        }}></div>
        <div style={{
          position: "absolute",
          width: "50px",
          height: "50px",
          zIndex: 2001,
          top: "50%",
          left: "50%",
          margin: "-25px 0 0 -25px",
        }}>
          <ReactLoading type={"spin"} color={"#0085d2"} />
        </div>
      </div>
    : ""
  );
}
