import React, { useState, useEffect} from 'react';
import './Patient/style.css';
import { Button } from '@mui/material';

export default function AlreadyConnected (props) {
    const [logo,setLogo]=useState()
    useEffect(()=>{
        let logoName = localStorage.getItem("logo");
        if (!logoName || logoName === "null") {
          setLogo("")
        } else if (logoName.startsWith("http://") || logoName.startsWith("https://")) {
          setLogo(logoName)
        } else {
          setLogo(process.env.REACT_APP_UPLOAD_URL + logoName)
        }
    },[])
    const handleHref=()=>{
      if(typeof window.location.replaceWithoutWarn === "function") {
        window.location.replaceWithoutWarn("#/")
      } else {
        window.location.href='./'
      }
    }

    return (
        <div className='root'>
            {/* <HiddenMenu /> */}
            <img className='top_tap' src={window.locationTopBanner} alt="top_tap" />
            <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
            <div className='d-flex flex-column align-items-center justify-content-start w-50 h-100 px-5'> 
              <div className='d-flex align-items-end justify-content-center p-3' 
                style={{height: "30vh", width: "45vw"}}>
                { logo &&<img style={{maxHeight: "100%", maxWidth: "100%", zIndex: 1}} src={logo}/>}
              </div>
              <div>
                <h3>Multiple Sessions Detected</h3>
                <h4>
                It appears you are already logged in from another location. To continue using our application, 
                please log out from the other session or contact support for assistance. 
                For security reasons, only one active session is allowed per user.
                </h4>
              </div>
                <Button className='my-3 px-5 py-3 font-weight-bold' onClick={handleHref}>Reload</Button>
            </div>

        </div>
    )
}
AlreadyConnected.prototype = {

}
