import React from "react";
import { styled } from '@mui/material/styles';
import { useEffect, useState,useRef } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Storages from '../../constants/storages'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import AdminService from '../../services/api'
import {
    MenuItem,
    ListItem,
    ListItemText,
    ListItemIcon,
    Popper,
    ClickAwayListener,
    MenuList,
    Grow,
    Paper,
  } from "@mui/material";
import { useSelector,useDispatch } from "react-redux";
import { selectCamera  } from "../../redux/cameraSlice";
import { toast } from "react-toastify";
const PREFIX = 'CameraDevices';
const MenuItemStyled = styled(MenuItem)(({ theme: {palette, mode} }) => ({
    color: palette.color[mode],
    '&:hover': {backgroundColor : palette.lightGray[mode]},
    '&.Mui-selected': {backgroundColor : palette.lightGray[mode]},
    '&.Mui-selected:hover': {backgroundColor : palette.lightGray[mode]},
    '&.Mui-focusVisible': { backgroundColor: palette.lightGray[mode]},
  }));

const classes = {
    color: `${PREFIX}-color`,
    menuList: `${PREFIX}-menuList`,
};

const Root = styled('div')(({
    theme: {palette,mode}
}) => ({
    [`& .${classes.color}`]: {
      color:palette.color[mode]
    },
    [`& .${classes.menuList}`]: {
      backgroundColor: palette.list[mode],
    },
}));

export default function CameraDevices({show}) {

    const [openMenu, setOpenMenu] = useState(false);
    const anchorRef=useRef()
    const [selectedCamera,setSelectedCamera]=useState({})
    const cameras=useSelector((state)=>state.cameras)
    const dispatch = useDispatch();

    useEffect(()=>{
        let camera= cameras.find((s)=>s.selected===true)
        setSelectedCamera(camera)
    },[cameras])
    
    useEffect(()=>{
        if(!show) return
        let camera= cameras.find((s)=>s.selected===true)
        if(!camera) return
        setSelectedCamera(camera)
        showLocalVideo(camera)
    },[show])

    const handleClickMenutItemCamera=(event)=>{
        setOpenMenu((prev)=>!prev);
    }

    
    const handleOnClickCamera=async(ev,item)=>{
        setOpenMenu(false);
        setSelectedCamera(item)
        // localStorage.setItem(Storages.LOCAL_CAMERA_ID,item.deviceId)
        dispatch(selectCamera({deviceId:item.deviceId}))
        var userType=localStorage.getItem(Storages.LOCAL_ROLE)
        let label=item.label || null
        AdminService.createActivityLog({
          tags:[userType, 'camera'],
          action: "update-device",
          subAction: "camera",
          target: "__ME__",
          details: userType + " set camera to " + label
        })
    }

    const handleCloseMenuCamera=(event)=>{
        setOpenMenu(false);
    }
    const showLocalVideo=(camera)=>{
        var deviceId=camera && camera.deviceId
        if(!deviceId) deviceId=localStorage.getItem(Storages.LOCAL_CAMERA_ID)
        if(!deviceId) return
        navigator.mediaDevices.getUserMedia({
            audio:false,
            video:{deviceId: { exact: deviceId }
        }}).then((steam)=>{
            var localVideoEl=document.getElementById('localVideo')
            if(localVideoEl) localVideoEl.srcObject=steam
        }).catch((err)=>{
            console.error(1982,err)
            toast.error(err.message)
        });
    }

    return (
        <Root>
            <div className="my-2">
                <label>Camera</label>    
                <ListItem 
                    button
                    aria-haspopup="true"
                    aria-controls="menu-list-cameras"
                    onClick={handleClickMenutItemCamera}
                    ref={anchorRef}
                    >
                    <ListItemIcon>
                        <VideocamOutlinedIcon  className={classes.color} />
                    </ListItemIcon>
                    <ListItemText className="text-truncate" title={(selectedCamera && selectedCamera.label) || ""} 
                        primary={
                            <span className={classes.color}>{
                                (selectedCamera && selectedCamera.label ) || "choose camera"
                            }</span>
                        }/>
                    <ListItemIcon>
                        <ArrowDropDownIcon  className={classes.color} />
                    </ListItemIcon>
                </ListItem>
                <Popper open={openMenu}  anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-start" style={{zIndex:1}}>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}>
                        <Paper >
                            <ClickAwayListener onClickAway={handleCloseMenuCamera}>
                            <MenuList autoFocusItem={openMenu} className={classes.menuList} id="menu-list-cameras">
                            {(cameras.length===0)?<MenuItemStyled onClick={(e)=>{e.preventDefault();setOpenMenu(false)}}>No camera found</MenuItemStyled>:
                                cameras.map((item, index) => 
                                <MenuItemStyled 
                                  onClick={(event) =>{handleOnClickCamera(event, item)}}
                                  selected={selectedCamera && (item.deviceId === selectedCamera.deviceId)} 
                                  key={index} 
                                  value={index}>
                                  {item.label || "No label"}
                                </MenuItemStyled>
                            )}
                            </MenuList>
                            </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                </Popper>
            </div>
            <div className="my-3 d-flex text-center justify-content-center">
                <video style={{
                    width:"95%",
                    height:"250px",
                    display:"flex",
                    borderRadius:"5px", 
                    backgroundColor:"gray",
                   }} autoPlay='1'  id='localVideo' playsInline />
            </div>
        </Root>
    );
}
