import React, { useEffect, useState, useMemo, useRef } from 'react';
import * as $ from 'jquery';
import LocationArea from '../../Components/Location/LocationArea';
import UserArea from '../../Components/User/UserArea';
import apiService from '../../services/api';
import { toast } from 'react-toastify';
import Constant from '../../constants/constant';
import Str from '../../constants/string';
import Storages from '../../constants/storages';
import './style.css';
import './bootstrap.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { addMic, selectMic, deleteMics } from '../../redux/micSlice';
import { addCamera, selectCamera, deleteCameras } from '../../redux/cameraSlice';
import { setShowSecondaryLocations } from '../../redux/showSecondaryLocations';
import { useContext } from 'react';
import { SocketContext } from '../../context/socket';
import { ThemeToggleContext } from '../../context/theme'
import RequestPaymentModal from '../../Components/Modal/RequestPayment';
import PrintRequestModal from '../../Components/Modal/PrintRequest';
import PrintFileRequestModal from '../../Components/Modal/PrintFileRequest';
import ScanRequestModal from '../../Components/Modal/ScanRequest';
import LocationNameBox from '../../Components/Location/LocationNameBox'
import { GalleryModal } from '../../Components/Modal/Gallery'
import CardDetectionRequest from '../../Components/Modal/CardDetectionRequest';
import syncSharedFiles from '../../services/syncSharedFiles';
import { setUnReadMessages } from '../../redux/messages'
import { createVirtualBackgroundEffect } from '../../services/virtual-background';
import Connection from '../../Components/User/Connection';
import { trackMuteChange } from '../../redux/tracksMuted';
import { setRemoteStat, clearRemotesID, setAllRemoteStats } from '../../redux/remoteStats';
import LocationCallTag from '../../Components/Location/LocationCallTag';
import { ReportIssue } from '../../Components/Modal/ReportIssue';
import { addSpeaker, selectSpeaker } from '../../redux/speaker';
import { SettingsModal } from '../../Components/Reception/Setting';
import { UserDeviceManagement } from '../../Components/User/UserDeviceManagement';
import tokenService from '../../services/tokenService';
import constant from '../../constants/constant';
// import {createAuth0Client} from '@auth0/auth0-spa-js';
import usePreventFileDrop from '../../services/usePreventFileDrop';
import useBeforeUnload from '../../services/useBeforeUnload';
import { MessagingBoardModal } from '../../Components/Modal/MessagingBoards';
import ReceptionFooter from '../../Components/Reception/ReceptionFooter';
import Loading from '../../Components/Reception/Loading';
import { styled } from '@mui/material/styles';
import TopAlert from '../../Components/Reception/TopAlert';
import str from '../../constants/string';
import jitsiConfigService from '../../services/jitsiConfigService';

var updtLocActvIntv
let holdTimerInterval = null;
let checkCallStateInterval = null;
let getStatsInterval = null;
let virtualBackgroundEffect;
let patientSaidNotInCallOnce = false;
let muteCameraTimeout = null;

const MainLocationArea = styled('div')(({
  theme: { palette, mode }
}) => ({
  backgroundColor: palette.background[mode]
}))

export default function Doctor() {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext)
  const [userStatus, setUserStatus] = useState(Constant.LOGOUT);
  const [allReceptions, _setAllReceptions] = useState([]);
  const allReceptionsRef = useRef(allReceptions);
  const setAllReceptions = (newVal) => {
    _setAllReceptions(newVal);
    allReceptionsRef.current = newVal;
  }
  const [onlineLocations, _setOnlineLocations] = useState([]);
  const onlineLocationsRef = useRef(onlineLocations)
  const setOnlineLocations = (newVal) => {
    _setOnlineLocations(newVal)
    onlineLocationsRef.current = newVal;
  }
  const [engagedLocation, _setEngagedLocation] = useState(null)
  const engagedLocationRef = useRef(engagedLocation);
  const setEngagedLocation = (newVal) => {
    engagedLocationRef.current = newVal
    _setEngagedLocation(newVal)
  }
  const [localVideoTrack, _setLocalVideoTrack] = useState(null);
  const localVideoTrackRef = useRef(localVideoTrack)
  const setLocalVideoTrack = (newVal) => {
    localVideoTrackRef.current = newVal
    _setLocalVideoTrack(newVal)
  }
  const [localAudioTrack, _setLocalAudioTrack] = useState(null);
  const localAudioTrackRef = useRef(localAudioTrack)
  const setLocalAudioTrack = (newVal) => {
    localAudioTrackRef.current = newVal
    _setLocalAudioTrack(newVal)
  }
  const [locationVideoTrack, _setLocationVideoTrack] = useState(null);
  const locationVideoTrackRef = useRef(locationVideoTrack);
  const setLocationVideoTrack = (newVal) => {
    locationVideoTrackRef.current = newVal
    _setLocationVideoTrack(newVal)
  }
  const [locationAudioTrack, _setLocationAudioTrack] = useState(null);
  const locationAudioTrackRef = useRef(locationAudioTrack);
  const setLocationAudioTrack = (newVal) => {
    locationAudioTrackRef.current = newVal
    _setLocationAudioTrack(newVal)
  }
  const [callState, _setCallState] = useState(false);
  const callStateRef = useRef(callState)
  const setCallState = (newVal) => {
    callStateRef.current = newVal
    _setCallState(newVal)
  }
  const [playState, _setPlayState] = useState(false);
  const playStateRef = useRef(playState)
  const setPlayState = (newVal) => {
    playStateRef.current = newVal
    _setPlayState(newVal)
  }
  const [camera, setCamera] = useState(true); // we have to start camera unmute, because of jitsi bug.
  const [mic, setMic] = useState(true);
  const mics = useSelector((state) => state.mics);
  const cameras = useSelector((state) => state.cameras);
  const connections = useSelector((state) => state.connections)
  const joinStateRef = useRef(false)
  const [allLocations, _setAllLocations] = useState([])
  const allLocationsRef = useRef(allLocations)
  const setAllLocations = (newVal) => {
    allLocationsRef.current = newVal;
    _setAllLocations(newVal);
  };
  const [localConnectionQuality, setLocalConnectionQuality] = useState({ jvbRTT: 0, connectionQuality: 0 })
  const { mode } = useContext(ThemeToggleContext)
  const [showPrintFileModal, setShowPrintFileModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const isLogout = useRef(false);
  const room = useRef(null);
  const listRemoteUsersRef = useRef([]);
  const localTracksRef = useRef([]);
  const connection = useRef(null);
  const volumeWarningShowed = useRef(false);
  const [showPayReqModal, setShowPayReqModal] = useState(false);
  const [showScanReqModal, setShowScanReqModal] = useState(false)
  const [showGalleryModal, setShowGalleryModal] = useState(false)
  const [defaultBatch, setDefaultBatch] = useState()
  const [showCardDetectionModal, setShowCardDetectionModal] = useState(false)
  const [locationVolume, setLocationVolume] = useState(0)
  const captureImageToastID = useRef(null)
  const [showIssueReport, setShowIssueReport] = useState(false)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [showDeviceManagement, setShowDeviceManagement] = useState(false)
  const [callLoading, _setCallLoading] = useState(false)
  const callLoadingRef = useRef(callLoading)
  const setCallLoading = (newVal) => {
    callLoadingRef.current = newVal
    _setCallLoading(newVal)
  }
  const callLoadingTimeoutRef = useRef()
  const monitorCallLocationRef = useRef(false);
  const monitorCallReceptionRef = useRef(false);
  const [monitorCallStatus, _setMonitorCallStatus] = useState(false);
  const [monitorCallHold, setMonitorCallHold] = useState(false)
  const monitorCallStatusRef = useRef(false);
  const setMonitorCallStatus = (newVal) => {
    monitorCallStatusRef.current = newVal;
    if (newVal) {
      setMonitorCallHold(false);
      handleCancelEngagedLocation();
    }
    _setMonitorCallStatus(newVal)
  }
  const [locationUsernameForDeviceManager, setLocationUsernameForDeviceManager] = useState()
  const LocationCallTagMemo = useMemo(() => LocationCallTag, [callState, onlineLocations, engagedLocation])
  const UserDeviceManagementMemo = useMemo(() => UserDeviceManagement, [showDeviceManagement, locationUsernameForDeviceManager])
  const SettingsModalMemo = useMemo(() => SettingsModal, [showSettingsModal, callState])
  const ReportIssueMemo = useMemo(() => ReportIssue, [showIssueReport, allLocations, engagedLocation])
  const callToken = useRef({})
  const myUsername = useRef(localStorage.getItem(Storages.LOCAL_LOGINED_USER))
  const [showModalMBoard, setShowModalMBoard] = useState(false)
  const waitingForHangupLocationUsername = useRef("");
  const waitedLocationHangedup = useRef(false);
  const cameraErrorShown = useRef(false);
  const microphoneErrorShown = useRef(false);
  const lastLocationResToCallState = useRef(null);
  const [locationConnectionIssue, setLocationConnectionIssue] = useState(false);
  const [msgBoardLocUsername, setMsgBoardLocUsername] = useState()
  const remoteStats = useSelector((state) => state.remoteStats);
  const remoteStatsRef = useRef(remoteStats);
  const rightSidebar = useSelector((state) => state.rightSidebar);
  const rightSidebarRef = useRef(rightSidebar);
  const lastReceiverConstraints = useRef();
  const [isTopAlert, setIsTopAlert] = useState(false);
  const finalJitsiUrlRef = useRef("");

  // To prevent the default behavior of the browser when files are dropped onto the page
  usePreventFileDrop();

  // To show a warning when user is trying to leave the page.
  useBeforeUnload();

  useEffect(() => {
    // in api, on connection socket checked if reception already connected in another session.
    socket && socket.disconnect()
    socket && socket.connect()
    console.alert(7781, "Doctor useEffect[]")

    getListLocations()
    localStorage.removeItem("reload_lock")
    dispatch(setShowSecondaryLocations(Boolean(localStorage.getItem("showSecondaryLocations"))))

    // updateUserStatus(Constant.LOGIN);
    socketOn()
    if (window.JitsiMeetJS) {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.alert(1390, "!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices");
        setCamera(false)
        setMic(false)
        toast.error(`
                No media device found. Please reload. 
                If this issue persists, please contact customer support.
              `, { autoClose: 10000 })
        return;
      }
      const maxVideoHeight = Number(localStorage.getItem(Storages.MAX_HEIGHT_VIDEO) || 1080)
      if (maxVideoHeight <= 180) {
        toast.warn(`
                The performance slider is set too low, which 
                ${maxVideoHeight == 0 ? "will disable all videos" : "will result in poor video quality"}.
                Please consider increasing the video quality in the settings.
              `, { autoClose: 10000 })
      }
      //This function is used by jitsi meet
      //https://www.whatismyip.com/webrtc/
      if (typeof RTCPeerConnection === "undefined") {
        console.alert(1386, "!RTCPeerConnection");
        window.confirmAsync.show(
          <h6 className='confirm-async-header'>Error</h6>,
          <span className='confirm-async-body'>
            WebRTC is not enabled in your browser. VS needs this feature to be able to work.
            Please contact customer support.
          </span>,
          [
            { value: 0, color: "primary", text: "Reload", close: 1 },
          ]
        ).then(() => window.location.replaceWithoutWarn("#/"))
        return;
      }

      getAllReceptionList();

      window.$ = $
      window.jQuery = $

      const initOptions = jitsiConfigService.getInit()
      window.JitsiMeetJS.init(initOptions);
      var logLevel = localStorage.getItem("Jitsi_Log_Level")
      if (logLevel && logLevel != '') {
        window.JitsiMeetJS.setLogLevel(window.JitsiMeetJS.logLevels[logLevel]);
      }

      const token = localStorage.getItem("jitsiJwt")
      const jitsiConfig = jitsiConfigService.getConnection();
      finalJitsiUrlRef.current = jitsiConfig.hosts.domain
      localStorage.setItem("finalJitsiUrl", finalJitsiUrlRef.current)
      connection.current = new window.JitsiMeetJS.JitsiConnection(null, token, jitsiConfig);

      connection.current.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, onConnectionSuccess);
      connection.current.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_FAILED, onConnectionFailed);
      connection.current.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, unload);

      connection.current.addEventListener(window.JitsiMeetJS.errors.track.TRACK_IS_DISPOSED, (err) => console.error(3130, err));
      connection.current.addEventListener(window.JitsiMeetJS.errors.conference.RESERVATION_ERROR, (err) => console.error(3133, err));
      connection.current.addEventListener(window.JitsiMeetJS.errors.conference.VIDEOBRIDGE_NOT_AVAILABLE, (err) => console.error(3132, err));
      connection.current.addEventListener(window.JitsiMeetJS.errors.track.NOT_FOUND, (err) => console.error(3134, err));
      connection.current.addEventListener(window.JitsiMeetJS.errors.track.TRACK_NO_STREAM_FOUND, (err) => console.error(3135, err));

      connection.current.connect();

      dispatch(deleteMics());
      dispatch(deleteCameras());
      if (window.JitsiMeetJS.mediaDevices.isDeviceChangeAvailable(Str.STR_INPUT)) {
        window.JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
          let cameraIndex = -1, micIndex = -1, spIndex = -1;
          const videoInputDevices = devices.filter((d) => d.kind === Str.STR_VIDEO_INPUT);
          if (!videoInputDevices[0]) {
            setCamera(false)
            toast.error(`No camera found. Please reload. 
                        If this issue persists, please contact customer support.
                      `)
          }
          const audioInputDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_INPUT);
          if (!audioInputDevices[0]) {
            setMic(false)
            toast.error(`No microphone found. Please reload. 
                        If this issue persists, please contact customer support.
                      `)
          }
          const audioOutputDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_OUTPUT);
          if (!audioOutputDevices[0]) {
            toast.error(`No speaker found. Please reload. 
                        If this issue persists, please contact customer support.
                      `)
          }
          var cameraDeviceId = localStorage.getItem(Storages.LOCAL_CAMERA_ID);
          var micDeviceId = localStorage.getItem(Storages.LOCAL_MIC_ID);
          var speakerDeviceId = localStorage.getItem(Storages.LOCAL_SPEAKER_ID);
          audioInputDevices.forEach((item, index) => {
            if (item.deviceId == "communications") {
              return;
            }
            let label = item.label;
            let mic = { id: index, deviceId: item.deviceId, label, selected: false }
            if (item.deviceId === micDeviceId) {
              micIndex = index;
              mic.selected = true;
            }
            dispatch(addMic(mic));
          })

          if (micIndex === -1 && audioInputDevices.length) {
            micDeviceId = audioInputDevices[0].deviceId;
            // localStorage.setItem(Storages.LOCAL_MIC_ID,micDeviceId)
            dispatch(selectMic({ deviceId: micDeviceId }));
          }

          window.JitsiMeetJS.createLocalTracks({
            devices: [Str.STR_AUDIO],
            micDeviceId: micDeviceId
          })
            .then(onLocalTracks)
            .catch(error => {
              handleErrorTrack(error, 1498, 'microphone')
              // console.error(1498, error)
              setMic(false)
              // toast.error(`There is an issue with audio track. Please reload. 
              //   If this issue persists, please contact customer support.
              // `)
            });
          videoInputDevices.forEach((item, index) => {
            let camera = { id: index, deviceId: item.deviceId, label: item.label, selected: false }
            if (item.deviceId === cameraDeviceId) {
              cameraIndex = index;
              camera.selected = true;
            }
            dispatch(addCamera(camera));
          })

          if (cameraIndex === -1 && videoInputDevices.length) {
            cameraDeviceId = videoInputDevices[0].deviceId;
            // localStorage.setItem(Storages.LOCAL_CAMERA_ID,cameraDeviceId)
            dispatch(selectCamera({ index: 0 }));
          }

          window.JitsiMeetJS.createLocalTracks({
            devices: [Str.STR_VIDEO],
            cameraDeviceId: cameraDeviceId
          })
            .then(onLocalTracks)
            .catch(error => {
              handleErrorTrack(error, 1495, 'camera')
              // console.error(1495, error)
              setCamera(false)
              // toast.error(`There is an issue with video track. Please reload. 
              //   If this issue persists, please contact customer support.
              // `)
            });
          // Speaker
          audioOutputDevices.forEach((item, index) => {
            if (item.deviceId == "communications") {
              return;
            }
            let label = item.label;
            let sp = { id: index, deviceId: item.deviceId, label, selected: false }
            if (sp.deviceId === speakerDeviceId) {
              spIndex = index;
              sp.selected = true;
            }
            dispatch(addSpeaker(sp));
          })
          if (spIndex === -1 && audioOutputDevices.length) {
            speakerDeviceId = audioOutputDevices[0].deviceId;
            localStorage.setItem(Storages.LOCAL_SPEAKER_ID, speakerDeviceId)
            dispatch(selectSpeaker({ deviceId: speakerDeviceId }));
          }
          if (speakerDeviceId) {
            if (document.getElementById('locationAudio')) {
              document.getElementById('locationAudio').setSinkId(speakerDeviceId)
            }
            if (document.getElementById('mainRAudio')) {
              document.getElementById('mainRAudio').setSinkId(speakerDeviceId)
            }
            if (document.getElementById('alarmAudio')) {
              document.getElementById('alarmAudio').setSinkId(speakerDeviceId)
            }
          }
        });
      }
      // A hack for jitsi mute and unmute bug,
      // we have to start camera unmute, because of jitsi bug, and then mute it after 10 sec to reduce BW.
      // https://community.jitsi.org/t/lib-jitsi-meet-mute-then-unmute-leads-to-frozen-remote-video-track/78713
      muteCameraTimeout = setTimeout(() => {
        setCallStateAndLocalVideo(false)
      }, 10000)

      holdTimerInterval = setInterval(() => {
        let onlineLocationsTmp = [...onlineLocationsRef.current];
        let holdFound = false;
        onlineLocationsTmp.forEach((remoteUser) => {
          if (remoteUser.status === Constant.HOLD) {
            holdFound = true;
            remoteUser.holdTimer++
          }
        });
        if (holdFound) {
          setOnlineLocations(onlineLocationsTmp);
        }
      }, 1000)
    }

    // when electron send user-leave, this function call
    if (window.electron) {
      window.electron.userLeave(async () => {
        try {
          setShowLoading(true)
          // await window.electron.auth0Logout()
          var token = tokenService.get();
          if (token) {
            await apiService.userLeave({
              username: myUsername.current,
            })
            await unload(false)
            window.electron.allWindowsClose()
          } else {
            await unload(false)
            window.electron.allWindowsClose()
          }
        } catch (err) {
          setShowLoading(false)
          console.error(1494, err)
          window.confirmAsync.show(
            <h6 className='confirm-async-header'>Error</h6>,
            <span className='confirm-async-body'>
              There was an error when trying to exit. Do you want to exit anyway?
            </span>,
            [
              { value: 1, color: "primary", text: "Yes", close: 1 },
              { value: 0, color: "default", text: "No", close: 1 }
            ]
          ).then((value) => {
            if (value === 1) {
              window.electron.allWindowsClose()
            }
          })
        }
      });
      window.electron.toggleDarkMode(mode)
      window.electron.startTracerouteInterval(process.env.REACT_APP_API_URL, finalJitsiUrlRef.current)
    }

    return (() => {
      // clearInterval(intervalId);
      if (!window.electron) unload()
      if (updtLocActvIntv) clearInterval(updtLocActvIntv)
      if (getStatsInterval) clearInterval(getStatsInterval)
      clearInterval(holdTimerInterval)

      localVideoTrack && localVideoTrack.detach($(`#mainRVideo`)[0]);
      localAudioTrack && localAudioTrack.detach($(`#mainRAudio`)[0]);
      disconnectSocket()
    })
  }, []);

  useEffect(() => {
    if (localVideoTrack) {
      if (camera) {
        localVideoTrack.attach($(`#mainRVideo`)[0]);
      } else {
        localVideoTrack.detach($(`#mainRVideo`)[0]);
      }
    }
  }, [camera, localVideoTrack])

  useEffect(() => {
    remoteStatsRef.current = remoteStats;
  }, [remoteStats]);

  useEffect(() => {
    rightSidebarRef.current = rightSidebar;
    setReceiverCons()
  }, [rightSidebar]);

  useEffect(() => {
    setReceiverCons();
  }, [locationVideoTrack, monitorCallStatus])

  useEffect(() => {
    if (callState) {
      clearTimeout(muteCameraTimeout)
    }
  }, [callState])

  useEffect(() => {
    if (!joinStateRef.current || room.current === null) {
      return;
    }

    let lastCameraId = localStorage.getItem(Storages.LOCAL_CAMERA_ID)
    cameras.forEach(async (cell) => {
      if (cell.selected === true && (!lastCameraId || lastCameraId != cell.deviceId)) {
        window.JitsiMeetJS.createLocalTracks({
          devices: [Str.STR_VIDEO],
          cameraDeviceId: cell.deviceId
        })
          .then(onLocalTracks)
          .catch(error => {
            handleErrorTrack(error, 1477, 'camera')
            if (cell.deviceId != lastCameraId) {
              let cmr = cameras.find((c) => c.deviceId == lastCameraId)
              if (cmr) {
                dispatch(selectCamera({ deviceId: cmr.deviceId }))
              }
            }
          });
      }
    })
  }, [cameras])

  useEffect(() => {
    if (!joinStateRef.current || room.current === null) {
      return;
    }

    let lastMicId = localStorage.getItem(Storages.LOCAL_MIC_ID)
    mics.forEach((cell) => {
      if (cell.selected === true && (!lastMicId || lastMicId != cell.deviceId)) {
        window.JitsiMeetJS.createLocalTracks({
          devices: [Str.STR_AUDIO],
          micDeviceId: cell.deviceId
        })
          .then(onLocalTracks)
          .catch(error => {
            handleErrorTrack(error, 1475, 'microphone')
            if (cell.deviceId != lastMicId) {
              dispatch(selectMic({ deviceId: lastMicId }))
            }
          });
      }
    })
  }, [mics])

  const clearCallLoadingTimeout = () => {
    if (callLoadingTimeoutRef.current) {
      clearTimeout(callLoadingTimeoutRef.current)
      callLoadingTimeoutRef.current = null
    }
  }

  const disconnectSocket = () => {
    if (socket) {
      socket.off('setLocationLabel')
      socket.off('msg')
      socket.off('join')
      socket.off('leave')
      socket.off("unSleep")
      socket.off("sleep")
      socket.off("locations")
      socket.off("reloadSharedFiles")
      socket.off("receptionStatus")
      socket.off("showHangupMessage")
      socket.off("manager")
      socket.off("locationIsAway")
      socket.off("callHoldStatus");
      socket.disconnect()
    }
  }

  const getListLocations = async () => {
    try {
      var result = await apiService.getListLocations()
      if (result.data.code === 0) {
        if (result.data.data && result.data.data[0]) {
          setAllLocations(result.data.data)
        }
      }
      else toast.error(result.msg, 5000)
    } catch (err) {
      console.error(err)
      toast.error(err.message, 10 * 1000)
    }
  }

  const socketOn = () => {
    if (socket) {
      socket.on('setLocationLabel', ({ username, label }) => {
        let onlineLocationsTmp = [...onlineLocationsRef.current]
        if (onlineLocationsTmp) {
          let foundLocation = onlineLocationsTmp.find(item => item.username == username);
          if (foundLocation) {
            foundLocation.calltag = label;
            setOnlineLocations(onlineLocationsTmp)
          }
        }
      })
      socket.on('msg', ({ event, msg, from }) => {
        if (event === 'scan-result-is-ready') {
          const batchID = msg.batchID
          setDefaultBatch(batchID)
          toast.success(
            <div>Image saved successfully. <a href='javascript:void(0)' onClick={handleOpenGallery}>Open in gallery</a></div>,
            { closeOnClick: true, autoClose: 5000 }
          );
          // handleCloseScanRequestModal()
          setShowCardDetectionModal(false)
        } else if (event === "sendVolumeToReception") {
          setLocationVolume(Number(msg.volume) || 100);
          if (msg.volume < 20 && !volumeWarningShowed.current) {
            toast.warning("Location's audio volume is too low. You may consider increasing it.", { autoClose: 10000 })
            volumeWarningShowed.current = true;
          }
        } else if (event === 'capture-image-res') {
          toast.dismiss(captureImageToastID.current);
          if (msg.code === 0) {
            setDefaultBatch(msg.data.batchID)
            toast.success(
              <div>Image saved successfully. <a href='javascript:void(0)' onClick={handleOpenGallery}>Open in gallery</a></div>,
              { closeOnClick: true, autoClose: 5000 }
            );
          } else {
            toast.error(msg.msg, { autoClose: 10000 })
          }
        }
      })

      socket.on("location-lang-changed", ({ locationUsername, newLang }) => {
        let onlineLocationsTmp = [...onlineLocationsRef.current];
        if (onlineLocationsTmp) {
          let location = onlineLocationsTmp.find(item => item.username == locationUsername);
          if (location) {
            location.currentLangCode = newLang;
            setOnlineLocations(onlineLocationsTmp)
          }
        }
      })

      socket.on('locations', ({ locations }) => {
        console.alert(1391, "socket.on('locations', locations:", locations.length)
        setAllLocations(locations)
      })

      socket.on('join', (username) => { // when location connect to socket api
        console.alert(1392, "socket.on('join', username: ", username)
        let locations = [...allLocationsRef.current]
        locations = locations.map((loc) => {
          if (loc.username == username) {
            return { ...loc, isOnline: 1 }
          }
          return loc
        })
        setAllLocations(locations)
        if (engagedLocationRef.current?.username === username) {
          setLocationConnectionIssue(false);
        }
      })

      socket.on('leave', (username) => { // when location leave from socket api
        console.alert(1393, "socket.on('leave', username: ", username)
        let locations = [...allLocationsRef.current]
        locations = locations.map((loc) => {
          if (loc.username == username) {
            return { ...loc, isOnline: 0 }
          }
          return loc
        })
        setAllLocations(locations)
        if (engagedLocationRef.current?.username === username) {
          setCallLoading(false)
          clearCallLoadingTimeout()
          setLocationConnectionIssue(true);
        }
      })

      socket.on('sleep', ({ username }) => {
        let locations = [...allLocationsRef.current]
        var isChange = false
        locations = locations.map((loc) => {
          if (loc.username == username) {
            if (loc.isSleep == 0) isChange = true
            return { ...loc, isSleep: 1 }
          }
          return loc
        })
        if (isChange) setAllLocations(locations)
      })
      socket.on('unSleep', ({ username }) => {
        let locations = [...allLocationsRef.current]
        var isChange = false
        locations = locations.map((loc) => {
          if (loc.username == username) {
            if (loc.isSleep == 1) isChange = true
            return { ...loc, isSleep: 0 }
          }
          return loc
        })
        if (isChange) setAllLocations(locations)
      })
      socket.on('reloadSharedFiles', () => {
        if (window.electron) {
          syncSharedFiles().then((data) => {
            if (data.code !== 0) {
              console.error(1492, data)
              toast.error(data.msg, { autoClose: 10000 })
            }
          })
        }
      })

      socket.on("receptionStatus", ({ id, username, newStatus, newOtherpartid }) => {
        try {
          let allReceptionsTmp = [...allReceptionsRef.current];
          let found = allReceptionsTmp.find(item => item.username === username);

          if (found) {
            found.status = newStatus;
            found.otherpartid = newOtherpartid
            sortAndSetAllReceptions(allReceptionsTmp);
          } else {
            getAllReceptionList();
          }
          return;
        } catch (err) {
          console.error(1092, err)
        }
      })
      socket.on("showHangupMessage", ({ locationUsername, receptionName }) => {
        toast.info(
          <span>Your held call with <i>{locationUsername}</i> has been picked up by <i>{receptionName}</i>.</span>,
          { autoClose: false, closeOnClick: true }
        )
      })
      socket.on("manager", ({ isManager }) => {
        if (isManager) localStorage.setItem(Storages.IS_RECEPTION_MANAGER, isManager)
        else localStorage.removeItem(Storages.IS_RECEPTION_MANAGER)
      })
      socket.on("locationIsAway", ({ id, value }) => {
        let locations = [...allLocationsRef.current]
        let location = locations.find(item => item.userID == id);
        if (location && location.locationIsAway !== value) {
          location.locationIsAway = value
          setAllLocations(locations);
        }
      })
      socket.on("callHoldStatus", (data) => {
        if (data.hold) {
          callHoldOn(data.locationName);
        } else {
          callHoldOff(data.locationName);
        }
      })
    }
  }

  const getAllReceptionList = async () => {
    try {
      const res = await apiService.getAllReceptionList();
      if (res.data.code !== 0) {
        console.error(1499, res.data)
        toast.error(res.data.msg, { autoClose: 10000 });
        return;
      }

      sortAndSetAllReceptions(res.data.data);
      dispatch(setUnReadMessages(res.data.data))
    } catch (err) {
      console.error(1491, err)
      toast.error(err.message, { autoClose: 10000 })
    }
  }

  const onConnectionSuccess = async () => {
    const roomName = localStorage.getItem(Storages.LOCAL_ROOM_NAME)
    const confOptions = await jitsiConfigService.getConference(finalJitsiUrlRef.current)
    room.current = connection.current.initJitsiConference(roomName, confOptions);
    window.currentJitsiRoom = room.current
    console.alert(1394, "onConnectionSuccess, roomName:", roomName, "config", confOptions)
    room.current.on(window.JitsiMeetJS.events.conference.TRACK_ADDED, onTrackAdded);
    room.current.on(window.JitsiMeetJS.events.conference.TRACK_REMOVED, onRemoveTrack);
    room.current.on(window.JitsiMeetJS.events.conference.CONNECTION_FAILED, (e) => {
      console.error(1490, "events.conference.CONNECTION_FAILED", e)
      reload()
    });
    room.current.on(window.JitsiMeetJS.events.conference.CONFERENCE_DESTROYED, (e) => {
      console.error(1489, "events.conference.CONFERENCE_DESTROYED", e)
      reload()
    });
    room.current.on(window.JitsiMeetJS.events.conference.CONNECTION_ERROR, (e) => {
      console.error(1488, "events.conference.CONNECTION_ERROR", e)
      reload()
    });
    room.current.on(window.JitsiMeetJS.events.conference.CONFERENCE_DESTROYED, (e) => {
      console.error(1487, "window.JitsiMeetJS.events.conference.CONFERENCE_DESTROYED", e)
      reload()
    });
    room.current.on(window.JitsiMeetJS.events.conference.CONNECTION_ERROR, (e) => {
      console.error(1486, "window.JitsiMeetJS.events.conference.CONNECTION_ERROR,", e);
      reload()
    });
    room.current.on(window.JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
    room.current.on(window.JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
    room.current.on(window.JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED, handleParticipantPropertyChange);
    room.current.on(window.JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
    room.current.on(window.JitsiMeetJS.events.conference.CONFERENCE_LEFT, onConferenceLeft);

    room.current.on(window.JitsiMeetJS.errors.track.TRACK_IS_DISPOSED, (err) => console.error(3130, err));
    room.current.on(window.JitsiMeetJS.errors.conference.RESERVATION_ERROR, (err) => console.error(3133, err));
    room.current.on(window.JitsiMeetJS.errors.conference.VIDEOBRIDGE_NOT_AVAILABLE, (err) => console.error(3132, err));
    room.current.on(window.JitsiMeetJS.errors.track.NOT_FOUND, (err) => console.error(3134, err));
    room.current.on(window.JitsiMeetJS.errors.track.TRACK_NO_STREAM_FOUND, (err) => console.error(3135, err));

    room.current.on(window.JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, track => {
      let type = track.getType()
      let isMuted = track.isMuted()
      let id = track.getParticipantId()
      console.alert(6531, "TRACK_MUTE_CHANGED", id, type, "isMuted", isMuted)
      dispatch(trackMuteChange({ id, type, isMuted }))
    });

    room.current.on(
      window.JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,
      (updatedLocalStats) => {
        const newQuality = parseInt(updatedLocalStats.connectionQuality)
        if (newQuality < 30) {
          console.alert(1356, "localStats,", updatedLocalStats);
        }

        setLocalConnectionQuality({ jvbRTT: updatedLocalStats.jvbRTT, connectionQuality: newQuality })
      }
    );
    room.current.on(
      window.JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED,
      (id, newRemoteStat) => {
        const remotesID = []
        for (let u of listRemoteUsersRef.current) {
          remotesID.push(u.id)
        }
        dispatch(clearRemotesID(remotesID))
        const tmpRemoteStat = { ...remoteStatsRef.current[id], ...newRemoteStat }
        dispatch(setRemoteStat({ id, remoteStat: tmpRemoteStat }))
      }
    );

    window.logAllVideoTags = () => {
      for (let i = 0; i < $("video").length; i++) {
        const v = $("video")[i]
        let found = false;
        if (v.id && v.id.endsWith("locationvideo")) {
          const participantID = v.id.replace("locationvideo", "");
          const stats = room.current.connectionQuality.getStats();
          let output = { height: "", fps: "", codec: "" }
          if (stats.codec) {
            const codecs = stats.codec[participantID]
            for (let i in codecs) {
              output.codec += codecs[i].video ? "v:" + codecs[i].video : "";
              output.codec += codecs[i].audio ? "a:" + codecs[i].audio : "";
              output.codec += ","
            }
          }
          if (stats.framerate) {
            const framerates = stats.framerate[participantID]
            for (let i in framerates) {
              output.fps += framerates[i] + ","
            }
          }
          if (stats.resolution) {
            const resolutions = stats.resolution[participantID]
            for (let i in resolutions) {
              output.height += resolutions[i]?.height + ","
            }
          }
          output.codec = output.codec.replace(/,$|^,/ig, '')
          output.height = output.height.replace(/,$/, '')
          output.fps = output.fps.replace(/,$/, '')
          found = output.fps || output.height || output.codec
          if (found) {
            console.log(v, Number(output.fps), Number(output.height), output.codec)
          }
        }
        if (!found && v.srcObject) {
          const settings = v.srcObject.getTracks()[0].getSettings()
          console.log(v, settings.frameRate.toFixed(0), settings.height)
        }
      }
    }

    room.current.on(window.JitsiMeetJS.events.conference.ENDPOINT_MESSAGE_RECEIVED, (sender, data) => {
      const senderID = sender.getId()
      if (callStateRef.current && senderID == engagedLocationRef.current?.jitsiUserId && data.type == "inCallAnswer") {
        if (data.status === "notInCall") {
          // sometimes, when we transfer a call, the callEnd will be called before transfer,
          // which will caus this function to be called. So we ignore the first response
          if (patientSaidNotInCallOnce) {
            patientSaidNotInCallOnce = false;
            console.alert(1192, "location respond with notInCall", engagedLocationRef.current?.username)
            toast.error(<>Call ended as <i>{engagedLocationRef.current?.locationName}</i> disconnected.</>, { autoClose: false })
            currentCallEnd("LocationNotInCall")
          } else {
            patientSaidNotInCallOnce = true;
          }
        } else {
          lastLocationResToCallState.current = Date.now();
        }
      }
      if (data.type == "inCallQuestion") {
        if (callStateRef.current && senderID == engagedLocationRef.current?.jitsiUserId) {
          room.current.sendEndpointMessage(senderID, { type: "inCallAnswer", status: "inCall" });
        } else {
          room.current.sendEndpointMessage(senderID, { type: "inCallAnswer", status: "notInCall" });
        }
      }
    })
    for (let track of localTracksRef.current) {
      room.current.addTrack(track);
    }
    room.current.setDisplayName(myUsername.current);
    room.current.join();
    const myJitsiUserId = room.current.myUserId()
    localStorage.setItem("myJitsiUserId", myJitsiUserId)
    // jitsiMeetId will be empty if not jaas.
    apiService.updateSelfPartId(myJitsiUserId, localStorage.getItem("jitsiMeetId")).then((res) => {
      const data = res.data;
      if (data.code !== 0) {
        console.error("6752: ", data)
        toast.error("Error code 6752. Please contact customer support.", 10000);
      }
    }).catch((err) => {
      console.error("6753: ", err)
      toast.error("Error code 6753. Please contact customer support.", 10000)
    })
  }

  const reload = () => {
    console.alert(1395, "Reload called")
    unload().then(() => {
      setTimeout(() => {
        if (window.electron) {
          window.electron.reload();
        } else {
          window.location.replaceWithoutWarn("#/")
        }
      }, Constant.TEN_SEC_INTERVAL)
    })
  }

  const onUserJoined = (id, user) => {
    console.alert(1396, "onUserJoined, id:", id, "user:", user.getDisplayName())
    const userFound = listRemoteUsersRef.current.find((item) => item.id == id)
    if (userFound) {
      return
    }

    const newUser = { id: id, user: user };
    listRemoteUsersRef.current.push(newUser);
  }

  const onConnectionFailed = (error) => {
    // toast.error("Failed to connect to Jitsi. Retrying in " + Constant.TEN_SEC_INTERVAL/1000 + " seconds.", 9000);
    console.alert(1397, "onConnectionFailed", error)
    setTimeout(function () {
      //enter code here, it will run after wake up
      if (window.electron) {
        window.electron.reload();
      } else {
        window.location.replaceWithoutWarn("#/")
      }
    }, Constant.TEN_SEC_INTERVAL);
  }

  const unload = async (sendUserLeave) => {
    console.alert(1398, "unload, sendUserLeave:", sendUserLeave)
    isLogout.current = true;

    try {
      if (localVideoTrackRef.current !== null) {
        localVideoTrackRef.current.detach($(`#mainRVideo`)[0]);
      }

      if (localAudioTrackRef.current !== null) {
        localAudioTrackRef.current.detach($(`#mainRAudio`)[0]);
      }

      for (let track of localTracksRef.current) {
        track.dispose();
      }
      localTracksRef.current = [];
    } catch (error) {
      console.error(1483, error)
    }
    if (room.current !== null) {
      try {
        room.current.removeEventListener(window.JitsiMeetJS.events.conference.CONFERENCE_LEFT, onConferenceLeft);
        room.current.removeEventListener(window.JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
        await room.current.leave();
        room.current = null;
      } catch (error) {
        console.error(1484, error)
      }
    }

    if (connection.current !== null) {
      try {
        connection.current.removeEventListener(window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, unload);
        connection.current.removeEventListener(window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, onConnectionSuccess);
        connection.current.removeEventListener(window.JitsiMeetJS.events.connection.CONNECTION_FAILED, onConnectionFailed);
        await connection.current.disconnect();
        connection.current = null;
      } catch (error) {
        console.error(1485, error)
      }
    }

    if (sendUserLeave) {
      await userLeaved(myUsername.current);
    }
  }

  const onLocalTracks = (tracks) => {
    tracks.forEach((localTrack, index) => {
      if (localTrack.getType() === Str.STR_VIDEO) {
        localStorage.setItem(Storages.LOCAL_CAMERA_ID, localTrack.deviceId);
        localTrack.attach($(`#mainRVideo`)[0]);
        localTrack.attach($("#localVideo")[0]);
        setLocalVideoTrack(localTrack);
        const isNowBlur = localStorage.getItem("blurMyBackground");
        (async () => {
          try {
            if (isNowBlur) {
              if (!virtualBackgroundEffect) {
                virtualBackgroundEffect = await createVirtualBackgroundEffect()
              }
              localTrack.setEffect(virtualBackgroundEffect)
            }
          } catch (err) {
            console.error(err)
            toast.error("An error occurred and couldn't change blur background")
          }
        })();
        if (!camera) {
          localTrack.mute().catch((err) => {
            console.error(1876, err)
          });
        }
      } else if (localTrack.getType() === Str.STR_AUDIO) {
        localStorage.setItem(Storages.LOCAL_MIC_ID, localTrack.deviceId);
        localTrack.attach($(`#mainRAudio`)[0]);
        setLocalAudioTrack(localTrack);
        if (!mic) {
          localTrack.mute().catch((err) => {
            console.error(1886, err)
          });
        }
      }

      loadTrack(localTrack)
    });
  }

  const loadTrack = (localTrack) => {
    try {
      if (joinStateRef.current) {
        let tempTrack = null;
        room.current && room.current.getLocalTracks().forEach((track) => {
          if (track.getType() === localTrack.getType()) {
            tempTrack = track;
          }
        });
        if (tempTrack) {
          room.current.replaceTrack(tempTrack, localTrack);
        }
        else {
          room.current.addTrack(localTrack).catch((err) => console.error(14225, err));
        }
        localTracksRef.current.push(localTrack);
      } else {
        setTimeout(() => loadTrack(localTrack), 1000)
      }
    } catch (err) {
      console.error(1455, err)
      toast.error("Error while loading media tracks. Please reload", { autoClose: 5000 });
    }
  }

  const getTrackFromUserName = (username) => {
    let ret = null;
    if (username === myUsername.current) {
      ret = localTracksRef.current.find(item => item.getType() === Str.STR_VIDEO)
    } else {
      listRemoteUsersRef.current.forEach((cell) => {
        if (cell.user.getDisplayName() === username) {
          let tracks = cell.user._tracks;
          tracks.forEach((track) => {
            if (track.type === Str.STR_VIDEO) {
              ret = track;
            }
          })
        }
      });
    }

    return ret;
  }

  const onTrackAdded = async (track) => {
    try {
      if (track.isLocal()) {
        return;
      }

      const type = track.getType()
      const participantID = track.getParticipantId();
      let isMuted = track.isMuted()
      dispatch(trackMuteChange({ id: participantID, type, isMuted }))

      let trackOwnerInRemoteArr = listRemoteUsersRef.current.find(item => item.id == participantID)
      if (!trackOwnerInRemoteArr) return;

      const username = trackOwnerInRemoteArr.user.getDisplayName();

      console.alert(1400, "onTrackAdded after isLocal, id", participantID, "type", type, "username", username)

      const response = await apiService.getInfoFromUsername({ username })
      if (response.data.code !== 200) {
        console.error(1481, response.data)
        toast.error(response.data.message);
        return;
      }
      let usernameInfo = response.data.data.data;
      if (usernameInfo.type === Constant.RECEPTION) {
        if (!trackOwnerInRemoteArr.tracks) {
          trackOwnerInRemoteArr.tracks = [track]
          return
        } else {
          trackOwnerInRemoteArr.tracks = trackOwnerInRemoteArr.tracks.filter(item => item.type != track.type) // remove the same type tracks
          trackOwnerInRemoteArr.tracks.push(track)
          return;
        }
      }

      let onlineLocationsTmp = [...onlineLocationsRef.current];
      let foundLocation = onlineLocationsTmp.find(item => item.id === participantID);
      if (foundLocation) {
        if (type === Str.STR_VIDEO) {
          if (foundLocation.videotrack) {
            const locationVideoEl = $(`#${foundLocation.videotrack.getParticipantId() + 'location' + foundLocation.videotrack.getType()}`)[0]
            if (locationVideoEl) {
              foundLocation.videotrack.detach(locationVideoEl);
              track.attach(locationVideoEl);
            }
          }

          if (engagedLocationRef.current?.username === usernameInfo.username) {
            if (foundLocation.videotrack) {
              foundLocation.videotrack.detach($(`#locationVideo`)[0]);
            }
            track.attach($(`#locationVideo`)[0]);
            setLocationVideoTrack(track);
          }
          foundLocation.videotrack = track;
          foundLocation.othertrack = getTrackFromUserName(usernameInfo.otherpartid);
          foundLocation.status = usernameInfo.status;
        } else if (type === Str.STR_AUDIO) {
          foundLocation.audiotrack = track;

          if (usernameInfo.otherpartid === myUsername.current) {
            track.attach($(`#locationAudio`)[0]);
            setLocationAudioTrack(track);
          }
        }
        setOnlineLocations(onlineLocationsTmp);
        return;
      }

      let toBeInsertedInOnlineLocations = {
        id: participantID,
        user: trackOwnerInRemoteArr.user,
        videotrack: null,
        audiotrack: null,
        othertrack: null,
        calltag: usernameInfo.calltag,
        calltagColor: null,
        status: Constant.JOIN,
        locationname: usernameInfo.locationname,
        username: usernameInfo.username,
        desktopTrack: null,
        holdTimer: null,
        currentLangCode: usernameInfo.currentLang || usernameInfo.defaultLang,
        inCall: usernameInfo.status > Constant.INCOMING,
        callBy: null
      };
      if (usernameInfo.status > Constant.INCOMING) toBeInsertedInOnlineLocations.callBy = usernameInfo.otherpartid
      if (type === Str.STR_VIDEO) {
        toBeInsertedInOnlineLocations.videotrack = track;
        toBeInsertedInOnlineLocations.othertrack = getTrackFromUserName(usernameInfo.otherpartid);
        toBeInsertedInOnlineLocations.status = usernameInfo.status;
      } else if (type === Str.STR_AUDIO) {
        toBeInsertedInOnlineLocations.audiotrack = track;
        if (usernameInfo.otherpartid === myUsername.current) {
          track.attach($(`#locationAudio`)[0]);
          setLocationAudioTrack(track);
        }
      }

      onlineLocationsTmp.push(toBeInsertedInOnlineLocations);
      setOnlineLocations(onlineLocationsTmp);
    } catch (error) {
      console.error(1480, error)
      toast.error(Str.STR_SERVER_ACCESS_ERROR);
      return;
    };
  }

  const onRemoveTrack = (track) => {
    const participant = track?.getParticipantId();
    if (participant && onlineLocationsRef.current[0]) {
      const type = track.getType();
      console.alert(1401, "onRemoveTrack, id", participant, "type", type)
      if (type === "video") {
        track.detach($(`#${participant}locationvideo`)[0]);
      }
      else if (type === "audio") {
        track.detach($(`#${participant}locationaudio`)[0]);
      }
    }
  }

  const onConferenceJoined = async () => {
    if (isLogout.current === true || window.location.hash === "#/login") {
      await unload(true)
      return
    }
    joinStateRef.current = true;
    updateUserStatus(Constant.JOIN);
    setReceiverCons()
    if (getStatsInterval) clearInterval(getStatsInterval);
    getStatsInterval = setInterval(() => {
      if (!room.current || !remoteStatsRef.current) return;

      const stats = room.current.connectionQuality.getStats();
      let tmpRemoteStats = {}
      for (let p in remoteStatsRef.current) {
        let height = "", fps = "", codec = "";
        if (stats.resolution) {
          const resolutions = stats.resolution[p]
          for (let i in resolutions) {
            height += resolutions[i]?.height + ","
          }
        }
        if (stats.framerate) {
          const framerate = stats.framerate[p]
          for (let i in framerate) {
            fps += framerate[i] + ","
          }
        }
        if (stats.codec) {
          const codecs = stats.codec[p]
          for (let i in codecs) {
            codec += codecs[i].video ? "v:" + codecs[i].video : "";
            codec += codecs[i].audio ? "a:" + codecs[i].audio : "";
            codec += ","
          }
        }
        codec = codec.replace(/,$|^,/ig, '')
        height = height.replace(/,$/, '')
        fps = fps.replace(/,$/, '')
        tmpRemoteStats[p] = { ...remoteStatsRef.current[p], codec, height, fps }
      }
      dispatch(setAllRemoteStats(tmpRemoteStats))
    }, 3000);
  }

  const updateUserStatus = async (status, otherpartid) => {
    try {
      const updateRes = await apiService.updateReceptionStatus({ newStatus: status, newOtherpartid: otherpartid });
      if (updateRes?.data?.code !== 0) {
        console.error(1479, updateRes.data)
        toast.error(updateRes.data.msg);
      } else {
        setUserStatus(status);
      }
    } catch (err) {
      console.error(1479, err)
      toast.error(err.message);
    }
  }

  const onUserLeft = (jitsiUserId, user) => {
    console.alert(1405, "onUserLeft, jitsiUserId:", jitsiUserId, "user:", user.getDisplayName())
    if (callStateRef.current && engagedLocationRef.current?.jitsiUserId == jitsiUserId) {
      console.alert(89671, "Location left the room while we were in call", jitsiUserId, user.getDisplayName())
      currentCallEnd('Failed');
    }
    if (engagedLocationRef.current?.username == user.getDisplayName()) {
      setEngagedLocation(null)
      setCallLoading(false)
      clearCallLoadingTimeout()
      clearInterval(updtLocActvIntv)
    }

    let onlineLocationsTmp = onlineLocationsRef.current.filter(item => item.id != jitsiUserId)
    setOnlineLocations(onlineLocationsTmp);
    listRemoteUsersRef.current = listRemoteUsersRef.current.filter(item => item.id != jitsiUserId)

    if (isLogout.current === false) {
      userLeaved(user.getDisplayName());
    }
  }

  const onConferenceLeft = () => {
    if (isLogout.current === false) {
      console.alert(7781, "onConferenceLeft")
      if (window.electron) {
        window.electron.reload();
      } else {
        window.location.replaceWithoutWarn("#/")
      }
    }
  }

  const handleParticipantPropertyChange = (participant, propertyName, oldValue, newValue) => {
    // console.alert(
    //   1408,
    //   "handleParticipantPropertyChange",
    //   participant.getId(),
    //   participant.getDisplayName(),
    //   propertyName,
    //   oldValue,
    //   newValue
    // );
    // if (/*isSignalAccept.current === false || */newValue === Constant.NONE_VALUE) {
    //   return;
    // }
    if(!(propertyName === Str.STR_CALL_TYPE  && (oldValue === Str.KIOSK || oldValue ===str.LAUNCH_WEB))
         && newValue === Constant.NONE_VALUE) return
    if (propertyName === Str.STR_CALL_TYPE || propertyName === Str.STR_CALL_TYPE_COLOR) {
      if (!newValue) return
      let participantId = participant.getId();
      let onlineLocationsTmp = [...onlineLocationsRef.current];
      let foundLocation = onlineLocationsTmp.find(item => item.id === participantId)
      if (foundLocation) {
        if (propertyName === Str.STR_CALL_TYPE) {
          foundLocation.calltag = newValue !=Constant.NONE_VALUE?newValue : null
        } else if (propertyName === Str.STR_CALL_TYPE_COLOR) {
          foundLocation.calltagColor = newValue
        }
        setOnlineLocations(onlineLocationsTmp);
      }
      return;
    }
    /*handle call from receptions.*/
    let receptionName = participant.getDisplayName();

    let locationUsername = propertyName;
    switch (newValue) {
      case Constant.CALL_END_VALUE:
        callEnd(receptionName, locationUsername);
        break;
      case Constant.CALL_INCOMING_VALUE:
        callIncoming(receptionName, locationUsername);
        break;
      case Constant.CALL_ACCEPT_VALUE:
        callJoined(receptionName, locationUsername);
        break;
      default:
        break;
    }
  }

  const callEnd = (receptionName, locationUsername) => {
    console.alert(1410, "callEnd", receptionName, locationUsername)

    let myCall = false;
    if (engagedLocationRef.current?.username === locationUsername) {
      myCall = true;
      currentCallEnd();
    }

    if (!myCall) {
      let locUsername;
      let onlineLocationsTmp = [...onlineLocationsRef.current];
      let foundLocation = onlineLocationsTmp.find((item) => item.user.getDisplayName() == locationUsername)
      if (foundLocation) {
        locUsername = foundLocation.username
        foundLocation.status = Constant.JOIN;
        foundLocation.othertrack = null;
        foundLocation.inCall = false
        foundLocation.callBy = null
        foundLocation.calltag = null
        foundLocation.calltagColor = null
        console.log("call end: ",onlineLocationsTmp)
        setOnlineLocations(onlineLocationsTmp);
      }

      if (monitorCallStatusRef.current && monitorCallLocationRef.current.username == locUsername) {
        stopMonitorCall();
        toast.info("The call has ended.")
      }

      if (waitingForHangupLocationUsername.current === locationUsername) {
        waitedLocationHangedup.current = true;
      }
    }
  }

  const callIncoming = (receptionName, locationUsername) => {
    console.alert(1412, "callIncoming", receptionName, locationUsername)

    let onlineLocationTmp = [...onlineLocationsRef.current];
    let foundLocation = onlineLocationTmp.find(item => item.user.getDisplayName() === locationUsername)
    if (foundLocation) {
      foundLocation.status = Constant.INCOMING;
      setOnlineLocations(onlineLocationTmp);
    }
  }

  const callJoined = (receptionName, locationUsername) => {
    console.alert(1415, "callJoined", receptionName, locationUsername)

    if (engagedLocationRef.current?.username === locationUsername) {
      // This reception is engaged with this location while this location 
      // is going into call with another reception. So we should cancel engage
      handleCancelEngagedLocation()
    }

    let onlineLocationsTmp = [...onlineLocationsRef.current];
    let foundLocation = onlineLocationsTmp.find(item => item.user.getDisplayName() === locationUsername)
    if (foundLocation) {
      let rec = allReceptionsRef.current.find((r) => r.username === receptionName)
      let displayName = rec?.displayName || receptionName
      foundLocation.othertrack = getTrackFromUserName(receptionName);
      foundLocation.inCall = true;
      foundLocation.callBy = displayName
      setOnlineLocations(onlineLocationsTmp);
    }
  }

  const callHoldOn = (locationUsername) => {
    let onlineLocationsTmp = [...onlineLocationsRef.current];
    let foundLocation = onlineLocationsTmp.find(item => item.user.getDisplayName() === locationUsername)
    if (foundLocation) {
      foundLocation.status = Constant.HOLD;
      foundLocation.holdTimer = 1;
      setOnlineLocations(onlineLocationsTmp);
    }
    if (monitorCallLocationRef.current && monitorCallLocationRef.current.username == locationUsername) {
      setMonitorCallHold(true)
    }
  }

  const callHoldOff = (locationUsername) => {
    let onlineLocationsTmp = [...onlineLocationsRef.current];
    let foundLocation = onlineLocationsTmp.find(item => item.user.getDisplayName() === locationUsername)
    if (foundLocation) {
      foundLocation.status = Constant.CALL;
      delete foundLocation.holdTimer;
      setOnlineLocations(onlineLocationsTmp);
    }
    if (monitorCallLocationRef.current && monitorCallLocationRef.current.username == locationUsername) {
      setMonitorCallHold(false)
    }
  }

  const handleLocalCamera = (event) => {
    event.stopPropagation()
    if (localVideoTrackRef.current === null) {
      toast.error("No camera found. Please reload.")
      return;
    }

    setCamera(localVideoTrackRef.current.isMuted());
    if (localVideoTrackRef.current.isMuted()) {
      localVideoTrackRef.current.unmute().catch((err) => {
        console.error(1891, err)
      });
    } else {
      localVideoTrackRef.current.mute().catch((err) => {
        console.error(1892, err)
      });
      clearTimeout(muteCameraTimeout)
    }
    if (callStateRef.current === false) {
      muteCameraTimeout = setTimeout(() => {
        setCallStateAndLocalVideo(false)
      }, 5000)
    }
  }

  const handleLocalMic = (event) => {
    event.stopPropagation()
    if (!localAudioTrackRef.current) {
      toast.error("No microphone found. Please reload.")
      return;
    }

    setMic(localAudioTrackRef.current.isMuted());
    if (localAudioTrackRef.current.isMuted()) {
      localAudioTrackRef.current.unmute().catch((err) => {
        console.error(1889, err)
      });
    } else {
      localAudioTrackRef.current.mute().catch((err) => {
        console.error(1890, err)
      });
    }
  }

  const currentCallEnd = (status) => {
    if (!engagedLocationRef.current) {
      console.alert(9710, "currentCallEnd called but no engagedLocationRef.current")
      return
    }

    let wasEngagedLocation = { ...engagedLocationRef.current }

    var oldCallState = callStateRef.current
    if (isLogout.current === false) {
      updateUserStatus(Constant.JOIN);
    }

    clearInterval(checkCallStateInterval)
    volumeWarningShowed.current = false;

    handleCancelEngagedLocation()

    let onlineLocationsTmp = [...onlineLocationsRef.current];
    let foundLocation = onlineLocationsTmp.find((item) => item.id == wasEngagedLocation.jitsiUserId)
    if (foundLocation) {
      foundLocation.status = Constant.JOIN;
      foundLocation.othertrack = null;
      foundLocation.inCall = false
      foundLocation.callBy = null
      foundLocation.calltag = null
      foundLocation.calltagColor = null
    }
    setOnlineLocations(onlineLocationsTmp);

    if (oldCallState) {
      let data = { status };
      if (wasEngagedLocation.username) {
        data.token = callToken.current[wasEngagedLocation.username] || null
      }

      apiService.currentCallEnd(data)
        .then(
          response => {
            if (response.data.code !== 200) {
              console.error(1473, response.data.msg)
              toast.error(response.data.msg);
            }
            if (wasEngagedLocation.username) {
              callToken.current[wasEngagedLocation.username] = null
            }
          },
          error => {
            console.error(1472, error)
            toast.error(Str.STR_SERVER_ACCESS_ERROR);
          }
        );
    }

    localStorage.removeItem("reload_lock")
  }

  const handleLocationCall = async () => {
    try {
      if (!engagedLocationRef.current) {
        return;
      }

      let onlineLocationTmp = [...onlineLocationsRef.current];
      const foundLocation = onlineLocationTmp.find(item => item.id == engagedLocationRef.current.jitsiUserId);
      if (!foundLocation) {
        console.alert(88971, "handleLocationCall user was not found in onlineLocations", engagedLocationRef.current.jitsiUserId)
        return;
      }

      let properties = room.current.getParticipantById(engagedLocationRef.current.jitsiUserId)._properties
      let token = properties[constant.CALL_INCOMING_TOKEN]
      let locationUsername = engagedLocationRef.current.username

      if (callStateRef.current === false) {
        setCallLoading(true)
        localAudioTrackRef.current && localAudioTrackRef.current.unmute()
        var callLoadingTimeout = setTimeout(async () => {
          var callAddResult = await apiService.locationCallAdd({ locationName: locationUsername, token })
          if (callAddResult.data?.code == 1) {
            toast.warn("The location is being called by another reception.")
          } else if (callAddResult.data?.code != 0) {
            toast.error(callAddResult.data.msg, { autoClose: 10 * 1000 })
          }
          if (callAddResult.data?.code != 0) {
            setCallLoading(false)
            clearCallLoadingTimeout()
            return handleCancelEngagedLocation()
          }

          console.alert(80516, `I went into call with ${locationUsername}`, "mic", mics?.find(item => item.selected), "cam", cameras?.find(item => item.selected))
          if (window.electron) {
            window.electron.logSystemUsage()
          }

          callToken.current[locationUsername] = callAddResult.data.token

          localStorage.setItem("reload_lock", 1)
          room.current && room.current.setLocalParticipantProperty(locationUsername, Constant.NONE_VALUE);
          room.current && room.current.setLocalParticipantProperty(locationUsername, Constant.CALL_ACCEPT_VALUE);

          setCallStateAndLocalVideo(true);
          setPlayState(true);
          if (foundLocation.audiotrack) {
            foundLocation.audiotrack.attach($(`#locationAudio`)[0]);
            setLocationAudioTrack(foundLocation.audiotrack)
          }

          foundLocation.othertrack = localVideoTrackRef.current;
          foundLocation.status = Constant.CALL;

          setOnlineLocations(onlineLocationTmp);
          updateUserStatus(Constant.CALL, locationUsername);
          setCallLoading(false)
          //avoid start call after location left room.
          if (!callLoadingTimeoutRef.current) {
            return currentCallEnd() // check callEnd & handleCancelEngagedLocation function 
          }
          callLoadingTimeoutRef.current = null

          let callAddResultUser = callAddResult.data.data?.user
          if (callAddResultUser) {
            let tmpAllLocations = [...allLocationsRef.current];
            let locationFound = tmpAllLocations.find(item => item.userID == callAddResultUser.id)
            if (locationFound && locationFound.cardDetectionEnabled != callAddResultUser.cardDetectionEnabled) {
              locationFound.cardDetectionEnabled = callAddResultUser.cardDetectionEnabled
              setAllLocations(tmpAllLocations)
            }
          }

          startCheckCallStateInterval()
        }, 2000);
        callLoadingTimeoutRef.current = callLoadingTimeout
        // send to location for disable button disconnected.
        await apiService.sendMessage({ to: locationUsername, event: 'disable_call_end' })
      } else {
        room.current.setLocalParticipantProperty(locationUsername, Constant.NONE_VALUE);
        room.current.setLocalParticipantProperty(locationUsername, Constant.CALL_END_VALUE);
        currentCallEnd();
      }
    } catch (error) {
      console.error(2021, error)
      setCallLoading(false)
    }
  }

  const handleLocationPlay = async () => {
    if (callStateRef.current === false || !engagedLocationRef.current) {
      return;
    }

    let onlineLocationTmp = [...onlineLocationsRef.current];
    const foundLocation = onlineLocationTmp.find(item => item.id == engagedLocationRef.current.jitsiUserId)
    let locationUsername = foundLocation.user.getDisplayName();
    if (!locationUsername) {
      console.error(1275, "No location name on handleLocationPlay", locationUsername)
      toast.error("No location found with this name.", { autoClose: 5 * 1000 })
      return;
    }

    const token = callToken.current[locationUsername];

    if (foundLocation.status === Constant.CALL) {
      const updateRes = await apiService.updateCallHoldStatus({ hold: true, locationName: locationUsername, callToken: token });
      if (updateRes.data.code !== 0) {
        console.error(1271, "Error while putting the call on hold", updateRes.data)
        toast.error("Error while putting call on hold. Error code: " + updateRes.data.code, { autoClose: 5 * 1000 })
        return;
      }

      setPlayState(false);
      if (locationAudioTrackRef.current !== null) {
        locationAudioTrackRef.current.detach($(`#locationAudio`)[0]);
        setLocationAudioTrack(null);
      }

    } else if (foundLocation.status === Constant.HOLD) {
      const updateRes = await apiService.updateCallHoldStatus({ hold: false, locationName: locationUsername, callToken: token });
      if (updateRes.data.code !== 0) {
        console.error(1272, "Error while resuming the call.", updateRes.data)
        toast.error("Error while resuming the call. Error code: " + updateRes.data.code, { autoClose: 5 * 1000 })
        return;
      }

      setPlayState(true);

      if (foundLocation.audiotrack !== null) {
        foundLocation.audiotrack.attach($(`#locationAudio`)[0]);
        setLocationAudioTrack(foundLocation.audiotrack);
      }
    }
  }

  const handleClickLocationItem = async (jitsiUserId) => {
    try {
      if ((callStateRef.current && playStateRef.current) || callLoadingRef.current) {
        return;
      }
      const foundLocation = onlineLocationsRef.current.find(item => item.id == jitsiUserId);
      if (!foundLocation) {
        console.alert(96701, "User clicked on a location which was not in onlineLocations", jitsiUserId)
        return;
      }

      const username = foundLocation?.user.getDisplayName()
      if (engagedLocationRef.current?.username === username) return handleCancelEngagedLocation()

      closeAllModals();

      console.alert(1427, "handleClickLocationItem, username:", username)

      if (foundLocation.status > Constant.INCOMING) {
        setCallStateAndLocalVideo(true);
        if (foundLocation.status === Constant.HOLD) {
          setPlayState(false);
        }
        else {
          setPlayState(true);
        }
      } else {
        setCallStateAndLocalVideo(false);
        setPlayState(false);
      }

      setEngagedLocation({ jitsiUserId, username: foundLocation.username, locationName: foundLocation.locationname })

      if (locationVideoTrackRef.current !== null) {
        locationVideoTrackRef.current.detach($(`#locationVideo`)[0]);
      }

      if (locationAudioTrackRef.current !== null) {
        locationAudioTrackRef.current.detach($(`#locationAudio`)[0]);
      }

      if (foundLocation.videotrack !== null) {
        foundLocation.videotrack.attach($(`#locationVideo`)[0]);
        setLocationVideoTrack(foundLocation.videotrack);
      }

      if (foundLocation.audiotrack !== null) {
        setLocationAudioTrack(foundLocation.audiotrack);
      }

      await apiService.updateLastActivity(username)
      if (updtLocActvIntv) clearInterval(updtLocActvIntv)
      updtLocActvIntv = setInterval(async () => {
        try {
          await apiService.updateLastActivity(username)
        } catch (err) {
          console.error(80134, err)
        }
      }, Constant.UPDATE_ACTIVITY_INTERVAL_DELAY)
    } catch (err) {
      console.error(1470, err)
      toast.error(err.message, { autoClose: 10000 })
    }
  }

  const handleClickHoldLocation = async (username) => {
    try {
      if (callStateRef.current === true && playStateRef.current === true) {
        return;
      }

      const confirm = await window.confirmAsync.show(
        <h6 className='confirm-async-header'>Confirm</h6>,
        <h6 className='confirm-async-body'>
          Another reception is holding this call. Hang up that and continue here?
        </h6>,
        [
          { value: 1, color: "primary", text: "Yes", close: 1 },
          { value: 0, color: "default", text: "No", close: 1 }
        ]
      )
      if (confirm !== 1) {
        return;
      }

      setShowLoading(true)
      const location = onlineLocationsRef.current.find((l) => l.username === username);
      if (!location) {
        toast.error("No location found for this call")
        return
      }
      // hangUpHoldCall will tell location to hangup
      const hangUpResult = await apiService.hangUpHoldCall({ locationUsername: username });
      if (hangUpResult.data?.code !== 0) {
        console.error(1562, hangUpResult.data)
        toast.error(hangUpResult.data.msg, { autoClose: 10000 })
        setShowLoading(false)
        return
      }

      let checkAfter30SecondsTimeout;

      // After location hangs up, the callEnd method will be called. 
      // If callEnd is called and waitingForHangupLocationUsername, we will continue
      waitingForHangupLocationUsername.current = username;
      const waitForLocationToHangupInterval = setInterval(() => {
        if (waitedLocationHangedup.current) {
          waitingForHangupLocationUsername.current = "";
          waitedLocationHangedup.current = false;

          clearTimeout(checkAfter30SecondsTimeout)
          //MAAST_MAAL!
          setTimeout(async () => {
            await handleClickLocationItem(location.id);
            setTimeout(async () => {
              await handleLocationCall()
              setShowLoading(false)
            }, 500);
          }, 500);
        }
      }, 500)

      // If for some reason, callEnd is not called, show error
      checkAfter30SecondsTimeout = setTimeout(() => {
        toast.error("Some error occurred and we couldn't find the location you were trying to reach. Please call them manually.", { autoClose: 10 * 1000 })
        waitingForHangupLocationUsername.current = "";
        clearInterval(waitForLocationToHangupInterval)
        setShowLoading(false)
      }, 30 * 1000);

    } catch (err) {
      console.error(1567, err)
      toast.error(err.message, { autoClose: 10000 })
      setShowLoading(false)
    }
  }

  const handleCancelEngagedLocation = async () => {
    // if (callState === true && playState === true) return; // comment out otherwise endCall wont cancel engage
    if (callLoadingRef.current || !engagedLocationRef.current) return
    closeAllModals();
    if (monitorCallStatus) {
      stopMonitorCall();
      return;
    }
    console.alert(1428, "handleCancelEngagedLocation, username:", engagedLocationRef.current.username)

    if (updtLocActvIntv) clearInterval(updtLocActvIntv)
    setPlayState(false);
    setCallStateAndLocalVideo(false);
    if (locationVideoTrackRef.current !== null) {
      locationVideoTrackRef.current.detach($(`#locationVideo`)[0]);
      setLocationVideoTrack(null);
    }

    if (locationAudioTrackRef.current !== null) {
      locationAudioTrackRef.current.detach($(`#locationAudio`)[0]);
      setLocationAudioTrack(null);
    }
    setLocationConnectionIssue(false)
    setEngagedLocation(null)
  }

  const userLeaved = async (username) => {
    try {
      await apiService.userLeave({ username });
    } catch (error) {
      console.error(1468, error)
    }
  }

  const sortAndSetAllReceptions = (allReceptionsTmp) => {
    for (let i = 0; i < allReceptionsTmp.length - 1; i++) {
      for (let j = i + 1; j < allReceptionsTmp.length; j++) {
        if (allReceptionsTmp[i].status < allReceptionsTmp[j].status) {
          let item1 = { ...allReceptionsTmp[i] };
          let item2 = { ...allReceptionsTmp[j] };
          allReceptionsTmp[i] = item2;
          allReceptionsTmp[j] = item1;
        }
        else if (allReceptionsTmp[i].status === allReceptionsTmp[j].status && allReceptionsTmp[i].username.localeCompare(allReceptionsTmp[j].username) === 1) {
          let item1 = { ...allReceptionsTmp[i] };
          let item2 = { ...allReceptionsTmp[j] };
          allReceptionsTmp[i] = item2;
          allReceptionsTmp[j] = item1;
        }
      }
    }

    setAllReceptions(allReceptionsTmp);
  }

  const setCallStateAndLocalVideo = (flag) => {
    console.alert(1432, "setCallStateAndLocalVideo, flag:", flag)
    setCallState(flag);
    callStateRef.current = flag
    if (localVideoTrackRef.current !== null) {
      if (flag) {
        localVideoTrackRef.current.unmute().catch((err) => {
          console.error(1891, err)
        });
      }
      else {
        localVideoTrackRef.current.mute().catch((err) => {
          console.error(1892, err)
        });
      }
      setCamera(flag);
    } else {
      /**if location call is ended itself */
      localTracksRef.current.forEach((cell) => {
        if (cell.getType() === Str.STR_VIDEO) {
          if (flag === true) {
            cell.unmute().catch((err) => {
              console.error(1893, err)
            });
          }
          else {
            cell.mute().catch((err) => {
              console.error(1894, err)
            });
          }
          setCamera(flag);
          return;
        }
      });
    }
    if (localAudioTrackRef.current !== null) {
      if (flag) {
        localAudioTrackRef.current.unmute().catch((err) => {
          console.error(1895, err)
        });
      }
      else {
        localAudioTrackRef.current.mute().catch((err) => {
          console.error(1898, err)
        });
      }
      setMic(flag);
    } else {
      /**if location call is ended itself */
      localTracksRef.current.forEach((cell) => {
        if (cell.getType() === Str.STR_AUDIO) {
          if (flag === true) {
            cell.unmute().catch((err) => {
              console.error(1896, err)
            });
          }
          else {
            cell.mute().catch((err) => {
              console.error(1897, err)
            });
          }
          setMic(flag);
          return;
        }
      });
    }
    setCallState(flag);
    callStateRef.current = flag
  }

  const setReceiverCons = () => {
    if (!room.current) return

    const stageTracks = [];
    if (locationVideoTrackRef.current) {
      stageTracks.push(locationVideoTrackRef.current)
    } else if (monitorCallStatus) {
      const monitorReceptionVideoTrack = monitorCallReceptionRef.current.tracks.find(item => item.type == Str.STR_VIDEO)
      const monitorLocationVideoTrack = monitorCallLocationRef.current.videotrack;
      stageTracks.push(monitorReceptionVideoTrack, monitorLocationVideoTrack)
    }
    //{"constraints":{"4942f96f-v0":{"maxHeight":720},"ffbe59bb-v0":{"maxHeight":180}},"defaultConstraints":{"maxHeight":0},"lastN":-1,"onStageSources":["4942f96f-v0"],"selectedSources":[]}
    // setTimeout(() => {
    let conf = {
      defaultConstraints: { maxHeight: 180 },
      constraints: {},
      lastN: -1,
      // selectedSources: [],
      onStageSources: [], // in new jitsi version, we use sourceName instead of endpointID
    }
    // make all videos small
    for (let location of onlineLocationsRef.current) {
      // in new jitsi version, we use sourceName instead of endpointID
      if (location.videotrack && location.videotrack.getSourceName()) {
        conf.constraints[location.videotrack.getSourceName()] = { maxHeight: 180 };
      }
    }
    if (stageTracks[0]) {
      let maxHeightVideo = Number(localStorage.getItem(Storages.MAX_HEIGHT_VIDEO) || 1080)
      const defaultLastN = rightSidebarRef.current ? -1 : stageTracks.length
      for (let track of stageTracks) {
        if (track && track.getSourceName()) {
          // in new jitsi version, we use sourceName instead of endpointID
          console.alert(1377, `stageTrack ${track.getSourceName()}`)
          if (maxHeightVideo == 0) conf.lastN = 0  // Setting LastN to 0 means no participant video is received.
          else conf.lastN = defaultLastN
          conf.constraints[track.getSourceName()] = { maxHeight: maxHeightVideo }
          conf.onStageSources.push(track.getSourceName())
        }
      }
    }
    const confStr = JSON.stringify(conf);
    if (lastReceiverConstraints.current === confStr) return

    lastReceiverConstraints.current = confStr
    room.current.setReceiverConstraints(conf)
    // }, 500);
  }

  const handleRequestForScan = () => {
    setDefaultBatch('')
    setShowScanReqModal(true)
  }

  const handleCloseScanRequestModal = (click) => {
    // stupid mui calls this method on backdrop click
    if (click) {
      if (defaultBatch && defaultBatch != '') handleOpenGallery()
      setShowScanReqModal(false)
    }
  }

  const handleOpenGallery = () => {
    localStorage.setItem("reload_lock", 1)
    setShowGalleryModal(true)
  }

  const handleCloseGallery = () => {
    setShowGalleryModal(false)
    localStorage.removeItem("reload_lock")
  }

  const toggleShareScreen = async (username) => {
    try {
      if (!username) username = engagedLocationRef.current?.username
      if (!username) {
        return toast.warning("Location not found.")
      }

      console.alert(1440, "toggleShareScreen, location username:", username)
      await apiService.sendMessage({ to: username, event: 'toggle-share-screen' })
    } catch (err) {
      console.error(1464, err)
      toast.error(err.message, { autoClose: 10000 })
    }
  }

  const toggleBlur = async () => {
    try {
      if (localVideoTrackRef.current) {
        const isNowBlur = localStorage.getItem("blurMyBackground");
        if (isNowBlur) {
          localVideoTrackRef.current.setEffect(undefined)
          localStorage.removeItem("blurMyBackground")
        } else {
          if (!virtualBackgroundEffect) {
            virtualBackgroundEffect = await createVirtualBackgroundEffect()
          }
          localVideoTrackRef.current.setEffect(virtualBackgroundEffect)
          localStorage.setItem("blurMyBackground", 1)
        }
      }
    } catch (err) {
      console.error(err)
      toast.error("An error occurred and couldn't change blur background")
    }
  }

  const handleExitFullScreenEngaged = () => {
    if (monitorCallStatus) {
      stopMonitorCall();
      return;
    }
    if (!onlineLocationsRef.current) return
    handleCancelEngagedLocation()
  }

  const captureLocationCameraImage = async (username) => {
    try {
      if (!username) username = engagedLocationRef.current?.username
      if (!username) {
        return toast.warning("Location not found.")
      }
      captureImageToastID.current = toast.info(<div>Capturing. please wait...</div>, { autoClose: false });
      await apiService.sendMessage({
        to: username,
        event: "capture-camera-image"
      })
    } catch (err) {
      console.error(err)
      toast.error(err.message, { autoClose: 10000 })
    }
  }

  const openLocationDeviceManager = (username) => {
    if (!username) username = engagedLocationRef.current?.username
    if (!username) {
      return toast.warning("Location not found.")
    }
    setLocationUsernameForDeviceManager(username)
    setShowDeviceManagement(true)
  }

  const closeAllModals = () => {
    setShowDeviceManagement(false);
    setShowScanReqModal(false);
    setShowPrintFileModal(false);
    setShowPayReqModal(false);
    setShowCardDetectionModal(false);
    setShowModalMBoard(false);
  }

  const handleErrorTrack = (err, code, device) => {
    // show error related to track only once
    if (device == "camera") {
      if (cameraErrorShown.current) {
        return
      }
      cameraErrorShown.current = true
    }
    if (device == "microphone") {
      if (microphoneErrorShown.current) {
        return
      }
      microphoneErrorShown.current = true
    }

    console.error(code, device, err)
    toast.error(device + ": " + err.message, 5000);
  }

  const nonElectronUserLeave = async () => {
    try {
      setShowLoading(true)
      // const auth0Client = await createAuth0Client({
      //   domain: process.env.REACT_APP_AUTH0_DOMAIN,
      //   clientId: process.env.REACT_APP_AUTH0_CLIENTID,
      // });
      // if(await auth0Client.isAuthenticated()) {
      //   await auth0Client.logout({ logoutParams: { returnTo: window.location.origin }});
      // }
      var token = tokenService.get();
      if (token) {
        await apiService.userLeave({
          username: myUsername.current,
        })
        // Commented as it is firing in the useEffect's return function anyway. 
        // Calling it here as well will sometimes result in "You have already left the conference" error
        // await unload(false)
      } else {
        // await unload(false)
      }
      disconnectSocket()
      tokenService.remove()
      // window.location.href="#/"
      window.location.replaceWithoutWarn("#/")
    } catch (err) {
      setShowLoading(false)
      console.error(1494, err)
      window.confirmAsync.show(
        <h6 className='confirm-async-header'>Error</h6>,
        <span className='confirm-async-body'>
          There was an error when trying to exit. Do you want to exit anyway?
        </span>,
        [
          { value: 1, color: "primary", text: "Yes", close: 1 },
          { value: 0, color: "default", text: "No", close: 1 }
        ]
      ).then((value) => {
        if (value === 1) {
          disconnectSocket()
          tokenService.remove()
          // window.location.href="#/"
          window.location.replaceWithoutWarn("#/")
        }
      })
    }
  }

  const handleOpenMessagingBoard = (username) => {
    if (!username) username = engagedLocationRef.current?.username
    if (!username) {
      return toast.warning("Location not found.")
    }
    setMsgBoardLocUsername(username)
    setShowModalMBoard(true)
  }

  const startMonitorCall = async (username) => {
    if (monitorCallStatus) {
      stopMonitorCall();
    }

    const location = onlineLocationsRef.current.find((l) => l.username === username);
    if (!location) {
      toast.error("No location found for this call")
      return
    }
    monitorCallLocationRef.current = location

    if (!location.videotrack || !location.audiotrack) {
      toast.warn("No media track found for the location")
    }

    // Sometimes location.othertrack is null
    let reception;
    if (location.othertrack) {
      reception = listRemoteUsersRef.current.find(item => item.id == location.othertrack.ownerEndpointId && item.tracks)
    }
    // if not found, location.callBy is username, sometimes it is the same as displayname
    if (!reception && location.callBy) {
      // Sometimes two users with the same name are in listRemoteUsersRef but only one has tracks
      reception = listRemoteUsersRef.current.find(item => item.user?.getDisplayName() == location.callBy && item.tracks)
    }
    // if not found, find the reception in our own reception data, then find their tracks base on displayname
    if (!reception && allReceptionsRef.current[0]) {
      let receptionWithoutTracks = allReceptionsRef.current.find(item => item.otherpartid === location.username)
      if (receptionWithoutTracks) {
        reception = listRemoteUsersRef.current.find(item => item.user?.getDisplayName() == receptionWithoutTracks.username && item.tracks)
      }
    }

    if (!reception || !reception.user || !reception.tracks) {
      toast.error("No reception found for this call")
      return
    }
    monitorCallReceptionRef.current = reception

    let receptionVideoTrack = reception.tracks.find(item => item.type == Str.STR_VIDEO)
    let receptionAudioTrack = reception.tracks.find(item => item.type == Str.STR_AUDIO)

    if (!receptionVideoTrack || !receptionAudioTrack) {
      toast.warn("No media track found for the reception")
    }

    location.videotrack && location.videotrack.attach($("#monitorLocationVideo")[0])
    location.audiotrack && location.audiotrack.attach($("#monitorLocationAudio")[0])

    receptionVideoTrack && receptionVideoTrack.attach($("#monitorReceptionVideo")[0])
    receptionAudioTrack && receptionAudioTrack.attach($("#monitorReceptionAudio")[0])
    setMonitorCallStatus(true)
  }

  const stopMonitorCall = () => {
    if (monitorCallLocationRef.current) {
      monitorCallLocationRef.current.videotrack && monitorCallLocationRef.current.videotrack.detach($("#monitorLocationVideo")[0])
      monitorCallLocationRef.current.audiotrack && monitorCallLocationRef.current.audiotrack.detach($("#monitorLocationAudio")[0])
      monitorCallLocationRef.current = null
    }
    if (monitorCallReceptionRef.current && monitorCallReceptionRef.current.tracks) {
      let receptionVideoTrack = monitorCallReceptionRef.current.tracks.find(item => item.type == Str.STR_VIDEO)
      let receptionAudioTrack = monitorCallReceptionRef.current.tracks.find(item => item.type == Str.STR_AUDIO)
      receptionVideoTrack && receptionVideoTrack.detach($("#monitorReceptionVideo")[0])
      receptionAudioTrack && receptionAudioTrack.detach($("#monitorReceptionAudio")[0])
      monitorCallReceptionRef.current = null
    }
    setMonitorCallStatus(false)
  }

  const startCheckCallStateInterval = () => {
    clearInterval(checkCallStateInterval)
    setLocationConnectionIssue(false)
    patientSaidNotInCallOnce = false;

    // Setting an initial value for this variable so it wont be about last call or even null
    // By setting it to now, it will wait x seconds before showing network connection-
    // if location didn't answer inCallQuestion ever
    lastLocationResToCallState.current = Date.now()

    checkCallStateInterval = setInterval(() => {
      if (!room.current) {
        console.error(1181, "!room.current in checkCallStateInterval")
        clearInterval(checkCallStateInterval)
        return
      }
      if (!engagedLocationRef.current?.jitsiUserId) {
        console.error(1183, "!engagedLocationRef.current?.jitsiUserId in checkCallStateInterval")
        clearInterval(checkCallStateInterval)
        return
      }
      if (!callStateRef.current) {
        console.error(1185, "!callStateRef.current in checkCallStateInterval")
        clearInterval(checkCallStateInterval)
        return
      }

      room.current.sendEndpointMessage(engagedLocationRef.current.jitsiUserId, { type: "inCallQuestion" });

      if (lastLocationResToCallState.current && lastLocationResToCallState.current < (Date.now() - (7 * 1000))) {
        setLocationConnectionIssue(true)
      } else {
        setLocationConnectionIssue(false)
      }
    }, 2 * 1000);
  }

  const updateReceiverConstraints = () => {
    if (!locationVideoTrackRef.current) return
    setReceiverCons()
  }

  const topAlertCb = (isShowing) => {
    setIsTopAlert(isShowing)
  }

  return (
    <div className='doctor-root'>
      <Loading show={showLoading} />
      {
        !connections.internet ?
          <Connection msg="It seems like there is no internet connection. Retrying…" />
          : !connections.server ?
            <Connection msg="We are currently upgrading and updating our service. 
                  We apologize for any inconvenience this may cause. 
                  Please be patient while we apply these necessary changes, 
                  your system will be back up shortly."/>
            :
            <>
              <TopAlert connectionQuality={localConnectionQuality} cb={topAlertCb}/>
              <div className='main_body_area' style={(isTopAlert) ? { height: '96vh' } : { height: '100vh' }}>
                <UserArea
                  updateUserStatus={updateUserStatus}
                  onLocalTracks={onLocalTracks}
                  userStatus={userStatus}
                  allReceptions={allReceptions}
                  mic={mic}
                  camera={camera}
                  joinState={joinStateRef.current}
                  localConnectionQuality={localConnectionQuality}
                  setMic={setMic}
                  setCamera={setCamera}
                />
                <MainLocationArea className='main_body_location_area' id="mainBodyLocationArea">
                  <LocationArea
                    handleClickLocationItem={handleClickLocationItem}
                    handleClickHoldLocation={handleClickHoldLocation}
                    handleLocationCall={handleLocationCall}
                    callState={callState}
                    onlineLocations={onlineLocations}
                    allLocations={allLocations}
                    locationEngaged={engagedLocation?.username}
                    monitorCallStatus={monitorCallStatus}
                    monitorCallHold={monitorCallHold}
                    startMonitorCall={startMonitorCall}
                    micro={mic}
                    camera={camera}
                    handleDeviceManagement={openLocationDeviceManager}
                    handleOpenMessagingBoard={handleOpenMessagingBoard}
                    captureLocationCameraImage={captureLocationCameraImage}
                    toggleShareScreen={toggleShareScreen}
                  />
                  <LocationNameBox
                    name={engagedLocation?.locationName}
                    id={engagedLocation?.jitsiUserId}
                    locationConnectionIssue={locationConnectionIssue}
                  />
                  <LocationCallTagMemo
                    locations={onlineLocations}
                    callState={callState}
                    locationID={engagedLocation?.jitsiUserId}
                  />
                  <ReceptionFooter
                    handleOpenModalPayReq={() => setShowPayReqModal(true)}
                    handleRequestForScan={handleRequestForScan}
                    startCardDetection={() => setShowCardDetectionModal(true)}
                    showPrintFileModal={() => setShowPrintFileModal(true)}
                    handleLocalCamera={handleLocalCamera}
                    handleLocalMic={handleLocalMic}
                    handleLocationCall={handleLocationCall}
                    handleLocationPlay={handleLocationPlay}
                    handleOpenGallery={handleOpenGallery}
                    toggleShareScreen={() => { toggleShareScreen(engagedLocation?.username) }}
                    callState={callState}
                    locationEngaged={engagedLocation?.username}
                    allLocations={allLocations}
                    playState={playState}
                    mic={mic}
                    camera={camera}
                    handleExitFullScreenEngaged={handleExitFullScreenEngaged}
                    captureLocationCameraImage={() => { captureLocationCameraImage(engagedLocation?.username) }}
                    setShowModalSetting={setShowSettingsModal}
                    handleDeviceManagement={() => openLocationDeviceManager(engagedLocation?.username)}
                    callLoading={callLoading}
                    handleOpenMessagingBoard={() => handleOpenMessagingBoard(engagedLocation?.username)}
                    monitorCallStatus={monitorCallStatus}
                    openIssueReporter={() => setShowIssueReport(true)}
                  />
                </MainLocationArea>
                <RequestPaymentModal
                  showModal={showPayReqModal}
                  handleClose={() => setShowPayReqModal(false)}
                  locationUsername={engagedLocation?.username}
                  allLocations={allLocations}
                />
                <PrintRequestModal
                  allLocations={allLocations}
                  locationEngaged={engagedLocation?.username}
                />
                <ScanRequestModal
                  allLocations={allLocations}
                  locationEngaged={engagedLocation?.username}
                  open={showScanReqModal}
                  handleClose={handleCloseScanRequestModal}
                />
                <PrintFileRequestModal
                  allLocations={allLocations}
                  locationEngaged={engagedLocation?.username}
                  externalShowModal={showPrintFileModal}
                  externalClose={() => setShowPrintFileModal(false)}
                />
                <CardDetectionRequest
                  allLocations={allLocations}
                  locationEngaged={engagedLocation?.username}
                  open={showCardDetectionModal}
                  handleClose={() => setShowCardDetectionModal(false)}
                />
                <GalleryModal
                  showModal={showGalleryModal}
                  handleClose={handleCloseGallery}
                  defaultBatch={defaultBatch}
                />
                <ReportIssueMemo
                  allLocations={allLocations}
                  engagedLocation={engagedLocation?.username}
                  open={showIssueReport}
                  handleClose={() => setShowIssueReport(false)}
                  handleOpen={() => setShowIssueReport(true)}
                />
                <SettingsModalMemo
                  showModal={showSettingsModal}
                  handleClose={() => setShowSettingsModal(false)}
                  toggleBlur={toggleBlur}
                  callState={callState}
                  nonElectronUserLeave={nonElectronUserLeave}
                  updateReceiverConstraints={updateReceiverConstraints}
                />
                <MessagingBoardModal
                  showModal={showModalMBoard}
                  handleClose={() => setShowModalMBoard(false)}
                  location={msgBoardLocUsername}
                />
                <UserDeviceManagementMemo
                  locationVolume={locationVolume}
                  setLocationVolume={setLocationVolume}
                  open={showDeviceManagement}
                  handleClose={() => setShowDeviceManagement(false)}
                  locationUsername={locationUsernameForDeviceManager}
                />
              </div>
            </>
      }
    </div >
  )
}
