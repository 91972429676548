import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  DialogTitle,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import { SocketContext } from '../../context/socket';
import apiService from '../../services/api';
import { toast } from 'react-toastify';
var video, snapshot, worker;

const CardDetection = () => {
  const cardDetectionEnable = useRef(false);
  const cardDetectionCount = useRef(0);
  const [cardDetectionMsg, setCardDetectionMsg] = useState("");
  const batchIDRef = useRef()
  const socket = useContext(SocketContext);
  const [showModal, setShowModal] = useState(false)
  const uploadingQueueRef = useRef([]);
  const uploadIntervalRef = useRef(null);
  const fromReceptionRef = useRef("");
  const tfModelLoaded = useRef(false);
  const postProcessPromises = useRef(new Array(5));
  const processPromises = useRef(new Array(5));
  // we use the bellow variable to avoid showing error if the card wasn't in camera view only once
  const numTimesNoCard = useRef(0); 

  useEffect(() => {
    socket.on('msg', ({event, msg, from})=>{
      if(event === 'card-detection-on'){
        cardDetectionOn(msg, from)
      }
      if(event === 'card-detection-off'){
        setShowModal(false)
        reset()
      }
    })

    init();
  }, [])

  const init = async () => {
    try{
      worker = new Worker(new URL('./cardDetectionWorker', import.meta.url));
      worker.onmessage = async (e) => {
        if(!e.data) return

        const {type, index, code, msg, data} = e.data;
        switch (type) {
          case 'postprocess-res':
            if(postProcessPromises[index] && postProcessPromises[index].resolveFn) {
              postProcessPromises[index].resolveFn({code, msg, data})
            }
            break;
          case 'process-res':
            if(processPromises[index]?.resolveFn) {
              processPromises[index].resolveFn({ code, msg, data });
            }
            break;
          case 'loadmodel-res':
            tfModelLoaded.current = data.loaded;
            break;
          default:
            break;
        }
      };
      worker.postMessage({ type: 'loadmodel-req', data: { uploadURL: process.env.REACT_APP_UPLOAD_URL_NO_CORS, tfjsBackend: localStorage.getItem('tfjs_backend') } });
    } catch (err) {
      console.error(4981, err, err.message)
    }
  }

  const cardDetectionOn = async (msg, from) => {
    try{
      const cardDetectionEnabled = localStorage.getItem("cardDetectionEnabled")
      if(!cardDetectionEnabled) {
        await apiService.sendMessage({
          to: from,
          event: "card-detection-res",
          msg: {code: -77, msg: "This location does not have ID card detection."}
        })
        return;
      }
      if(!tfModelLoaded.current) {
        console.error(5081, "cardDetectionOn but model is not loaded")
        await apiService.sendMessage({
          to: from,
          event: "card-detection-res",
          msg: {code: -3, msg: "Card detection model is not initialized. Please reload the location."}
        })
        toast.error(window.i18n.getString("cardDetectionError"), {autoClose: 50 * 1000})
        return;
      }

      reset()
      setShowModal(true)
      cardDetectionEnable.current = true;
      fromReceptionRef.current = from
      detectOne()
      batchIDRef.current = localStorage.getItem("lucas_therapies_logined_username") + "-" + new Date().getTime()
    } catch (err) {
      console.error(1100, err)
      toast.error(window.i18n.getString("serverError"), {autoClose: 10000})
    }
  }

  const reset = () => {
    cardDetectionCount.current = 0;
    cardDetectionEnable.current = false;
    clearInterval(uploadIntervalRef.current)
    setCardDetectionMsg(window.i18n.getString("cardDetectionMsg"))
    uploadingQueueRef.current = []
    fromReceptionRef.current = ""
  }

  const fixBBoxRatio = (processed) => {
    const [x, y, width, height] = processed.bbox;
    const result = [ 
      (x - width / 2) * video.videoWidth / 640, 
      (y - height / 2) * video.videoHeight / 640, 
      (width * video.videoWidth) / 640, 
      (height * video.videoHeight) / 640, 
    ];
    return result;
  }

  const asyncCanvasBuffer = async (canvas) => {
    return new Promise((resolve, reject) => {
      try{
        canvas.toBlob(async (blob) => {
          if(!blob) {
            reject("blob is null")
            return
          }
          const buffer = await blob.arrayBuffer()
          resolve(buffer)
        }, 'image/jpeg');
      } catch (err) {
        reject(err)
      }
    })
  }

  const detectOne = async () => {
    try{
      video = document.getElementById(`mainVideo`);
      let videoWidth = video.videoWidth, videoHeight = video.videoHeight;
      snapshot = document.createElement('canvas');
      snapshot.width = videoWidth;
      snapshot.height = videoHeight;
      let snapshotCTX = snapshot.getContext('2d')
      snapshotCTX.drawImage(video, 0, 0, videoWidth, videoHeight);
      const imageData = snapshotCTX.getImageData(0, 0, videoWidth, videoHeight);
      const index = cardDetectionCount.current
      let resolveFn;
      let promise = new Promise((resolve) => {
        resolveFn = resolve;
      });
      processPromises[index] = { promise, resolveFn };
      worker.postMessage({ data: imageData, type: 'process-req', index });
      const {code, msg, data} = await promise;
      if(code == 0) {
        numTimesNoCard.current = 0;
        await makeObjectReadyForUpload(data)
        if(cardDetectionCount.current >= 5) {
          uploadAll();
          return;
        }
      } else if (code == 1) {
        numTimesNoCard.current++;
        if(numTimesNoCard.current > 5) {
          setCardDetectionMsg(window.i18n.getString("cardDetectionMsg"))
          apiService.sendMessage({
            to: fromReceptionRef.current,
            event: "card-detection-res",
            msg: {code: 1, msg: "There is no card visible in the video feed. Please ask the patient to bring the card into the camera’s view. Retrying automatically..."}
          }).catch((err) => {
            console.error(99815, err);
          })
        }
      } else {
        console.error(99822, msg)
        setCardDetectionMsg(window.i18n.getString("cardDetectionError"))
        apiService.sendMessage({
          to: fromReceptionRef.current,
          event: "card-detection-res",
          msg: {code: -5, msg: "Error while detecting ID card. Reason: " + msg + ". Retrying..."}
        }).catch((err) => {
          console.error(99815, err);
        })
      }
      
      if(cardDetectionEnable.current) {
        setTimeout(() => {
          detectOne()
        }, 300);
      }
    } catch (err) {
      // tensor errors don't log correctly with just console.error
      console.error(99812, err, err.message)
      setCardDetectionMsg(window.i18n.getString("cardDetectionError"))
      apiService.sendMessage({
        to: fromReceptionRef.current,
        event: "card-detection-res",
        msg: {code: -7, msg: "Error while detecting ID card. " + err.message}
      }).catch((err) => {
        console.error(99815, err);
      })
    }
  }

  const uploadAll = async () => {
    try{
      cardDetectionEnable.current = false;
      const msg = window.i18n.getString("cardDetectionUploading")
      setCardDetectionMsg(msg)
      apiService.sendMessage({
        to: fromReceptionRef.current,
        event: "card-detection-res",
        msg: {code: 0, msg}
      }).catch((err) => {
        console.error(8971, err);
      })
      for(let i in uploadingQueueRef.current) {
        await uploadOne(i)
      }
    } catch (err) {
      console.error(err)
      apiService.sendMessage({
        to: fromReceptionRef.current,
        event: "card-detection-res",
        msg: {code: -11, msg: "Error while uploading: " + err.message + " Retrying..."}
      }).catch((err) => {
        console.error(9908, err);
      })
      setTimeout(() => {
        uploadAll();
      }, 1000);
    }
  }

  const uploadOne = async (i) => {
    try{
      const file = uploadingQueueRef.current.find(item => item.i == i);
      if(!file || !file.buffer) {
        console.error(8891, "uploadOne, one file has no buffer.")
        return;
      }
      const axiosRes = await apiService.uploadScanResult({
        file: file.buffer, 
        batchID: batchIDRef.current,
        fromReception: fromReceptionRef.current,
        lastOneInABatch: uploadingQueueRef.current.length === 1,
      })
      if(axiosRes.data.code === 0) {
        uploadingQueueRef.current = uploadingQueueRef.current.filter(item => item.i != i);
        if(uploadingQueueRef.current.length === 0) {
          reset()
          setShowModal(false)
          toast.success(window.i18n.getString("success"), {autoClose: 5000});
        }
      } else {
        await apiService.sendMessage({
          to: fromReceptionRef.current,
          event: "card-detection-res",
          msg: {code: axiosRes.data.code, msg: "Error while uploading: " + axiosRes.data.msg + " Retrying..."}
        })
        setTimeout(() => {
          uploadOne(i)
        }, 1000);
      }
    } catch (err) {
      console.error(8891, err)
      apiService.sendMessage({
        to: fromReceptionRef.current,
        event: "card-detection-res",
        msg: {code: -13, msg: "Error while uploading: " + err.message + " Retrying..."}
      }).catch((err) => {
        console.error(1109, err);
      })
      setTimeout(() => {
        uploadOne(i)
      }, 1000);
    }
  }

  const makeObjectReadyForUpload = async (processed) => {
    try{
      const fixedBBox = fixBBoxRatio(processed);
      let [sx, sy, widthPx, heightPx] = fixedBBox;

      if(widthPx < 150 && heightPx < 150) {
        const msg = window.i18n.getString("cardDetectionCloser");
        setCardDetectionMsg(msg)
        apiService.sendMessage({
          to: fromReceptionRef.current,
          event: "card-detection-res",
          msg: {code: 0, msg}
        }).catch((err) => {
          console.error(8891, err);
        })
      } else {
        const msg = window.i18n.getString("cardDetectionHoldStill")
        setCardDetectionMsg(msg)
        apiService.sendMessage({
          to: fromReceptionRef.current,
          event: "card-detection-res",
          msg: {code: 0, msg}
        }).catch((err) => {
          console.error(1230, err);
        })
        const canvas = document.createElement('canvas');
        canvas.width = widthPx; 
        canvas.height = heightPx; 
        let ctx = canvas.getContext('2d') 
        ctx.drawImage(snapshot, sx, sy, widthPx, heightPx, 0, 0, widthPx, heightPx );
        const buffer = await asyncCanvasBuffer(canvas)
        uploadingQueueRef.current.push({i: cardDetectionCount.current, buffer})

        cardDetectionCount.current++;
      }
    } catch (err) {
       throw err;
    }
  }

  return (
    <div style={{
      position: "absolute", 
      marginTop: "40vh",
      zIndex: 2, 
      backgroundClip: "padding-box",
      border: "1px solid rgba(0,0,0,.2)",
      borderRadius: ".3rem",
      backgroundColor: "#FFF",
      display: showModal ? "block" : "none",
      paddingInline: 0
    }} className="offset-2 col-8 offset-md-3 col-md-6 offset-xl-4 col-xl-4">
      <DialogTitle disableTypography={true}>
        <h5>{window.i18n.getString("cardDetectionTitle")}</h5>
      </DialogTitle>
      <DialogContent>
        <div style={{marginTop: "0px", marginBottom: "5px"}}>{cardDetectionMsg}</div>
      </DialogContent>
      <br/>
      <LinearProgress />
    </div>
  )
}
export default CardDetection