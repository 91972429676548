import React, { useEffect, useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import {ListItem,ListItemText, ListItemButton} from '@mui/material';
import { toast } from 'react-toastify';
import AdminService from '../../services/api';
import storages from "../../constants/storages";
import { useSelector } from "react-redux";

const Root = styled('div')(({
    theme: {palette,mode}
    })=>({
        minWidth: '200px',
        minHeight:"100px",
        border: '5px',
        boxSizing: 'border-box',
        borderRadius:"5px",
        backgroundColor:palette.background[mode] + " !important",
        color:palette.color[mode] + " !important",
        zIndex: '3',
        [`& .context-item`]: {
            color:palette.color[mode]
        },
        ['& .item-padding']:{
            padding:"4px 16px"
        }
    }
))

export default ({
  contextMenuRef,
  location={locationname:"location"},
  show,
  handleCloseContextMenu,
  reloadLocation,
  handleDeviceManagement,
  handleOpenMessagingBoard,
  reload,
  deviceManagement,
  shareScreen,
  messagingBoard,
  monitor,
  startMonitorCall,
  takePicture,
  callState,
  captureLocationCameraImage,
  toggleShareScreen,
  hangupHeldCall,
  startHangupHeldCall
})=>{
    const [isManager, setIsManager] = useState(Boolean(localStorage.getItem(storages.IS_RECEPTION_MANAGER)))
    // handle click out side of context menu
    useEffect(()=>{
        function handleAutoCloseContextMenu(event) {
            if(event.type=="scroll" && show) 
                return handleCloseContextMenu()
            if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) 
                return handleCloseContextMenu()
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleAutoCloseContextMenu);
        document.addEventListener("scroll", handleAutoCloseContextMenu,true);
        setIsManager(Boolean(localStorage.getItem(storages.IS_RECEPTION_MANAGER)))

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleAutoCloseContextMenu);
            document.removeEventListener("scroll", handleAutoCloseContextMenu,true);
        };
    },[contextMenuRef, location])

    const handleReload=(e)=>{
        // call reload location
        reloadLocation(e,location.username)
        handleCloseContextMenu()
    }

    const onClickDeviceManagement=(e)=>{
        handleDeviceManagement(location.username)
        handleCloseContextMenu()
    }

    const onClickShareScreen=()=>{
        toggleShareScreen(location.username)
        handleCloseContextMenu()
    }
    const onMonitorClick=()=>{
        if(isManager && location.inCall && !callState) {
            window.confirmAsync.show(
              <h6 className="confirm-async-header">Confirm</h6>, 
              <span className="confirm-async-body">
                Would you like to Monitor this call?
              </span>, 
              [
                { value: 1, color: "primary", text: "Yes", close: 1 },
                { value: 0, color: "default", text: "No", close: 1 },
              ]
            ).then(async (value)=>{
              if(value) {
                startMonitorCall(location.username)
              }
            })
          }

        handleCloseContextMenu()
    }

    const onClickMessagingBoard=()=>{
        handleOpenMessagingBoard(location.username)
        handleCloseContextMenu()
    }

    const onClickTakePicture=()=>{
        captureLocationCameraImage(location.username)
        handleCloseContextMenu()
    }
    const onClickHangupHoldingCall=()=>{
        startHangupHeldCall(location.username)
        handleCloseContextMenu()
    }
    return(
        <Root
            ref={contextMenuRef}
            style={{ display: show ? 'block':'none', position:"fixed" }} >
            <ListItem >
              <ListItemText style={{borderBottom:"1px solid gray",paddingBottom:"0.5rem"}} >
                  <b className="context-item">{location && location.locationname || 'location'}</b>
              </ListItemText>
            </ListItem>
            <ListItemButton className="item-padding" button key={'deviceManagement'} onClick={onClickDeviceManagement} disabled={deviceManagement}>
              <ListItemText className='context-item' primary={
                  <span className="context-item">Device management</span>
              }/>
            </ListItemButton>
            <ListItemButton className="item-padding" button key={'messagingBoard'} onClick={onClickMessagingBoard} disabled={messagingBoard}>
              <ListItemText className='context-item' primary={
                  <span className="context-item">Messaging board</span>
              }/>
            </ListItemButton>
            {isManager &&
              <ListItemButton className="item-padding" button key={'monitor'} onClick={onMonitorClick} disabled={monitor}>
                <ListItemText className='context-item' primary={
                    <span className="context-item">Monitor this call</span>
                }/>
              </ListItemButton>
            }
            <ListItemButton className="item-padding" button key={'transferCall'} onClick={onClickHangupHoldingCall} disabled={hangupHeldCall}>
              <ListItemText className='context-item' primary={
                  <span className="context-item">Transfer call to me</span>
              }/>
            </ListItemButton>
            <ListItemButton className="item-padding" button  key={'General'} onClick={handleReload} disabled={reload}>
                <ListItemText className='context-item' primary={
                    <span className="context-item">Reload</span>
                }/>
            </ListItemButton>
            <ListItemButton title="Start / Stop location share screen" className="item-padding" button key={'shareScreen'}   onClick={onClickShareScreen} disabled={shareScreen}>
              <ListItemText className='context-item' primary={
                  <span className="context-item">Share screen</span>
              }/>
            </ListItemButton>
            <ListItemButton title="Take picture from location's camera" className="item-padding mb-2" button key={'takePicture'}   onClick={onClickTakePicture} disabled={takePicture}>
              <ListItemText className='context-item' primary={
                  <span className="context-item">Take picture</span>
              }/>
            </ListItemButton>
  
        </Root>
    )
}