import React, { useEffect , useState, memo, useContext, useRef } from 'react';
import { styled } from '@mui/material/styles';
import './modal-style.css';
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import AdminService from '../../services/api';
import { SocketContext } from '../../context/socket';
import Draggable from 'react-draggable';
import { withStyles,makeStyles } from '@mui/material/styles';
import { yellow, red } from '@mui/material/colors';
import PaymentIcon from '@mui/icons-material/Payment';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";

const PREFIX = 'RequestPaymentModal';

const classes = {
  dialogColor:`${PREFIX}-dialogColor`,
  dialogTitle: `${PREFIX}-dialogTitle`,
};

const StyledFormControlLabel = styled(FormControlLabel)(({ theme:{palette,mode} }) => ({
  "& .MuiFormControlLabel-label": {
    color: palette.color[mode],
  },
}));

const DialogStyled=styled(Dialog)(({
  theme:{palette,mode}
})=>({
  [`& .${classes.dialogTitle}`]: {
    cursor:"move",
    backgroundColor:palette.background[mode],
    color:palette.color[mode],
    '& .MuiTypography-h6':{
      color:palette.color[mode],
    }
  },
  [`& .${classes.dialogColor}`]: {
    backgroundColor:palette.background[mode],
    color:palette.color[mode],
    '& h6':{
      color:palette.color[mode]
    },
    '& button':{
      color:palette.color[mode]
    }
  },
    '& label.Mui-focused': {
      color: palette.color[mode],
    },
    '& label': {
      color: palette.color[mode],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: palette.color[mode],
    },
    '& .MuiOutlinedInput-root': {
      color: palette.color[mode],
      '& fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&:hover fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary[mode],
      },
    },
}))
const payApiIFrameStyles = `
  body {
    margin: 0px;
    font:75% verdana,arial,sans-serif;
  }
  .card-data {
    width:100%;
    padding:12px;
    border:1px solid #ccc;
    border-radius:4px;
    box-sizing:border-box;
    resize:vertical;
  }
  label {
    padding:12px 12px 12px 0;
    display:inline-block;
    color:#fff
  }
  select {
    background-color: white;
  }
  .card-data-submit {
    background-color:#4caf50;
    color:#fff;
    padding:12px 20px;
    border:none;
    border-radius:4px;
    cursor:pointer;
    float:right;
    margin-top:6px
  }
  .card-data-submit:hover {
    background-color:#45a049
  }
  .left-col {
    display: none;
    width: 0px;
  }
  .right-col {
    float:left;
    width:100%;
    margin-top: 10px
  }
  .row:after {
    content: "";
    display: table;
    clear: both
  }
`

const cardpointeIFrameStyle = `
  body {
    margin: 0px;
    font:75% verdana,arial,sans-serif;
  }
  input {
    width:100%;
    padding:12px;
    border:1px solid #ccc;
    border-radius:4px;
    box-sizing:border-box;
    resize:vertical;
  }
  select {
    background-color: white;
    width:46%;
    padding:12px;
    border:1px solid #ccc;
    border-radius:4px;
    box-sizing:border-box;
    resize:vertical;
  }
  label {
    display:none;
  }
`

const RequestPaymentModal = memo(({showModal, handleClose, locationUsername, allLocations})=>{
    const [desc, setDesc]=useState('Copay')
    const descRef = useRef()
    descRef.current = desc;
    const [amount, setAmount]=useState("")
    const amountRef = useRef()
    amountRef.current = amount;
    const [successConfirm, setSuccessConfirm] = useState({isOpen: false, amount: 0, patientName: "", receipt: ""});
    const [disableInputs, setDisableInputs] = useState(false);
    const [alertShow, setAlertShow] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState();
    const [alertMsg, setAlertMsg] = useState("");
    const [submitButtonText, setSubmitButtonText] = useState("Submit");
    const [trID, setTrID] = useState();
    const [cardNotPresent, setCardNotPresent] = useState(false);
    const [forceConnect, setForceConnect] = useState(false);
    const [locationPaymentCompany, setLocationPaymentCompany] = useState("");
    const [locationPaymentIframeUrl, setLocationPaymentIframeUrl] = useState("");
    const [locationPaymentIframeKey, setLocationPaymentIframeKey] = useState("");
    const [patientAddress, setPatientAddress] = useState("");
    const patientAddressRef = useRef()
    patientAddressRef.current = patientAddress;
    const [patientZip, setPatientZip] = useState("");
    const patientZipRef = useRef()
    patientZipRef.current = patientZip;
    const [beforeSubmitPatientName, setBeforeSubmitPatientName] = useState("");
    const beforeSubmitPatientNameRef = useRef()
    beforeSubmitPatientNameRef.current = beforeSubmitPatientName;
    const cpToken = useRef("")
    const cpExpiry = useRef("")
    const cpTokenError = useRef("")
    const socket = useContext(SocketContext);

    const dcTokenCallback = async (dcRes) => {
      if (dcRes.Error) {
        showAlert(`Token error: ${dcRes.Error}`, 'error')
        setSubmitButtonText("Submit")
        setDisableInputs(false)
      } else {
        createPaymentApiWithToken(dcRes.Token)
      }
    }

    const cpTokenCallback = async (event) => {
      const cardpointeIframeUrl = new URL(locationPaymentIframeUrl);
      if(event.origin != cardpointeIframeUrl.origin) {
        return;
      }
      var parsed = JSON.parse(event.data);
      if(parsed.errorCode != 0) {
        if(parsed.errorCode) {
          cpTokenError.current = `Error code: ${parsed.errorCode}: ${parsed.errorMessage}`
        } else {
          cpTokenError.current = `Unknown error. Please make sure all inputs are correct.`
        }
      } else {
        cpTokenError.current = "";
        cpToken.current = parsed.token;
        cpExpiry.current = parsed.expiry
      }
    }

    const createPaymentApiWithToken = async (token, expiry) => {
      setSubmitButtonText("In process with payment APIs ...")
      try{
        const axiosRes = await AdminService.createPaymentApi({
          amount: amountRef.current, 
          desc: descRef.current, 
          patientName: beforeSubmitPatientNameRef.current, 
          patientAddress: patientAddressRef.current,
          patientZip: patientZipRef.current,
          token,
          locationUsername,
          expiry
        })
        const resData = axiosRes.data;

        setSubmitButtonText("Submit")
        setDisableInputs(false)

        if(resData.code === 0) {
          setTrID(resData.data.trID)
          setSuccessConfirm({
            isOpen: true, 
            amount: resData.data.amount, 
            patientName: resData.data.patientName,
            receipt: resData.data.receipt,
          })
        } else {
          showAlert(resData.message, 'error')
        }

      } catch (err) {
        console.error(err)
        showAlert(err.message, 'error')
        setSubmitButtonText("Submit")
        setDisableInputs(false)
      }
    }

    useEffect(() => {
      if(cardNotPresent && locationPaymentCompany === "datacap") { // we can't call removeMessageEventListener before init.
        if(typeof window.DatacapHostedWebToken?.removeMessageEventListener === "function") {
          window.DatacapHostedWebToken.removeMessageEventListener();
        }
        window.DatacapHostedWebToken.init(
          locationPaymentIframeKey, 
          'payapi-token-iframe', 
          dcTokenCallback, 
          payApiIFrameStyles
        );
      }
      if(cardNotPresent && locationPaymentCompany === "cardpointe") {
        window.addEventListener('message', cpTokenCallback, false);
      }
    }, [cardNotPresent])

    const onCardNotPresentChange = (e) => {
      if(e.target.checked && (locationPaymentCompany !== "datacap" && locationPaymentCompany !== "cardpointe")) {
        showAlert("This location does not have payment company. Please contact admins.", 'error')
        return
      }
      setCardNotPresent(e.target.checked)
    }

    const onForceConnectChange = (e) => {
      setForceConnect(e.target.checked)
    }

    const onBeforeSubmitPatientNameChange = (e) => {
      setBeforeSubmitPatientName(e.target.value)
    }

    const onPatientAddressChange = (e) => {
      setPatientAddress(e.target.value)
    }

    const onPatientZipChange = (e) => {
      setPatientZip(e.target.value)
    }

    const onPatientNameChange = (e) => {
      setSuccessConfirm({...successConfirm, patientName: e.target.value})
    }

    const onChangeAmount=(e)=>{
      setAmount(e.target.value)
    }

    const onChangeDesc=(e)=>{
      setDesc(e.target.value)
    }

    const handleAlertClose = (e) => {
      setAlertShow(false)
    }

    function showAlert (msg, severity) {
      setAlertMsg(msg)
      setAlertSeverity(severity)
      setAlertShow(true)
    }

    useEffect(() => {
      if(showModal) {
        setCardNotPresent(false);
        setForceConnect(false);
        const thisLocation = allLocations.find(item => item.username == locationUsername);
        if(thisLocation){
          setLocationPaymentCompany(thisLocation.paymentCompany);
          setLocationPaymentIframeUrl(thisLocation.paymentIframeUrl);
          setLocationPaymentIframeKey(thisLocation.paymentIframeKey);
        }
      }
    }, [showModal])

    useEffect(() => {
      if(showModal) {
        const thisLocation = allLocations.find(item => item.username == locationUsername);
        if(thisLocation){
          setLocationPaymentCompany(thisLocation.paymentCompany);
          setLocationPaymentIframeUrl(thisLocation.paymentIframeUrl);
          setLocationPaymentIframeKey(thisLocation.paymentIframeKey);
        }
      }
    }, [allLocations, locationUsername])

    useEffect(() => {
      socket.on("paymentRequested", (data) => {
        setSubmitButtonText("In process with POS device ...")
      }); 

      socket.on("paymentResult", (data) => {
        setSubmitButtonText("Submit")
        setDisableInputs(false)

        if (data.code === 0) {
          setTrID(data.data.trID)
          setSuccessConfirm({
            isOpen: true,
            amount: data.data.amount,
            patientName: data.data.patientName,
            receipt: data.data.receipt,
          })
        } else {
          showAlert(data.message, 'error')
        }
      })

      return () => {
        socket.off("paymentRequested");
        socket.off("paymentResult");
        window.removeEventListener('message', cpTokenCallback)
      };
    }, []);

    const delay = () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 600);
      })
    }

    const submit = async() => {
      try{
        if(!amount || amount == 0) {
          showAlert(`Amount is required.`, 'error')
          return;
        }
        if (cardNotPresent && !beforeSubmitPatientName) {
          showAlert(`Patient name is required.`, 'error')
          return;
        }
        if (cardNotPresent && locationPaymentCompany === "cardpointe") {
          await delay();
          if(cpTokenError.current) {
            showAlert(cpTokenError.current, 'error')
            return;
          }
          if(!cpToken.current) {
            showAlert("No payment token, Please make sure all inputs are correct.", 'error')
            return;
          }
        }

        setDisableInputs(true)
        setSubmitButtonText("Submitting transaction ...")

        if (cardNotPresent) {
          if(locationPaymentCompany === "datacap") {
            window.DatacapHostedWebToken.requestToken()
          } else if (locationPaymentCompany === "cardpointe") {
            createPaymentApiWithToken(cpToken.current, cpExpiry.current)
          }
        } else {
          const res = await AdminService.createPaymentRequest({amount, desc, locationUsername, forceConnect})
          const resData = res.data;
          if(resData.code !== 0) {
            showAlert(`The transaction was not complete. Error: ${resData.message}`, 'error')
            setSubmitButtonText("Submit")
            setDisableInputs(false)
          } 
        }

      } catch (err) {
        console.error(err)
        showAlert(err.message, 'error')
        setSubmitButtonText("Submit")
        setDisableInputs(false)
      }
    }

    const handleSuccessConfirmSubmit = async () => {
      try{
        const res = await AdminService.updatePayment(trID, {patientName: successConfirm.patientName})
        const resData = res.data;
        if(resData.code === 0) {
          handleClose()
          setSuccessConfirm({isOpen: false, amount: 0, patientName: "", receipt: ""})
        } else {
          showAlert(resData.message, 'error')
        }
      } catch (err) {
        console.error(err)
        showAlert(`${err.message}`, 'error')
      }
    }

    const printReceipt = async () => {
      try {
        const res = await AdminService.printReceipt(trID);
        const resData = res.data;
        if(resData.code === 0) {
          toast.success("Printing in progress...")
        } else {
          showAlert(`Print failed. code: ` + resData.code, 'error')
        }
      } catch (err) {
        console.error(err)
        showAlert(`${err.message}`, 'error')
      }
    }

    return (
      <div>
        <DialogStyled
          fullWidth={true}
          maxWidth={"sm"}
          open={successConfirm.isOpen}
          disableBackdropClick={true}
        >
          <DialogContent className={classes.dialogColor}>
            <h6 className='text-capitalize mb-3'>
              <b>Transaction was successful</b>
            </h6>
            <h6 className='mb-3'>
              Amount: {successConfirm.amount}
            </h6>
            {successConfirm.receipt ? 
              <pre style={{width: "100%", height: "250px"}}>{successConfirm.receipt}</pre>
            : ""}
            <FormControl fullWidth variant="outlined" className='mt-1'>
              <InputLabel htmlFor="patientName">Patient Name</InputLabel>
              <OutlinedInput 
                label="Patient Name"
                id="patientName"
                value={successConfirm.patientName}
                onChange={onPatientNameChange}
                type={'text'}
                autoFocus
              />
            </FormControl>
          </DialogContent>
          <DialogActions className={classes.dialogColor+ ' px-4 pb-4'}>
            <Button onClick={handleSuccessConfirmSubmit} color="primary" variant='contained'>
              Submit
            </Button>
            <Button onClick={printReceipt} color="primary" variant='contained' 
              title="Printing the receipt on patient's printer">
              Print receipt
            </Button>
          </DialogActions>
        </DialogStyled>

        <DialogStyled
          fullWidth={true}
          maxWidth={"xs"}
          open={alertShow}
          onClose={handleAlertClose}
        >
          <DialogContent className={classes.dialogColor}>
            <DialogContentText>
              <h6 className='text-capitalize'>
                <b>{alertSeverity}</b>
              </h6>
              <h6>
                {alertMsg}
              </h6>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogColor}>
            <Button onClick={handleAlertClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </DialogStyled>

        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
          <DialogStyled
            open={showModal}
            onClose={handleClose}
            BackdropProps={{ style: { backgroundColor: "transparent" } }}
            disableBackdropClick="false"
          >
            <DialogTitle className={classes.dialogTitle}>
              <h5>Request Payment</h5>
            </DialogTitle>
            <DialogContent className={classes.dialogColor} >
              <FormControl className='mt-0' fullWidth variant="outlined">
                <label>Location: {locationUsername}</label>
              </FormControl>

              <FormControl fullWidth className="my-1" variant="outlined">
                <InputLabel htmlFor="amount">Amount*</InputLabel>
                <OutlinedInput
                  label="Amount*"
                  id="amount"
                  value={amount}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  onChange={onChangeAmount}
                  type={'number'}
                  step={"0.01"}
                  disabled={disableInputs}
                  autoFocus
                />
              </FormControl>

              <FormControl fullWidth className='mt-2' variant="outlined">
                <TextField
                  label="Description"
                  multiline
                  rows={4}
                  defaultValue="Copay"
                  value={desc}
                  variant='outlined'
                  onChange={onChangeDesc}
                  disabled={disableInputs}
                />
              </FormControl>

              <FormControl fullWidth className='mt-1' variant="outlined">
                { locationPaymentCompany === "cardpointe" && 
                  <StyledFormControlLabel
                    control={
                      <Checkbox
                        checked={forceConnect}
                        onChange={onForceConnectChange}
                        disabled={disableInputs}
                      />
                    }
                    className={classes.test + ' mb-0'}
                    label="Force connect (Terminate all other payments in this POS)"
                  />
                }
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      checked={cardNotPresent}
                      onChange={onCardNotPresentChange}
                      color="primary"
                      disabled={disableInputs || !locationPaymentIframeUrl}
                    />
                  }
                  className='mb-0'
                  label="Card not present"
                />
              </FormControl>

              { cardNotPresent &&
                <>
                <FormControl fullWidth className='mt-1' variant="outlined" >
                  <input placeholder="Patient Name*" disabled={disableInputs}
                    style={{
                      width: "100%",
                      padding: "12px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      boxSizing: "border-box",
                      resize: "vertical",
                      font: "90% verdana,arial,sans-serif"
                    }} value={beforeSubmitPatientName} onChange={onBeforeSubmitPatientNameChange} />
                </FormControl>
                <FormControl fullWidth className='mt-2' variant="outlined" >
                  <input placeholder="Patient Address" disabled={disableInputs}
                    style={{
                      width: "100%",
                      padding: "12px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      boxSizing: "border-box",
                      resize: "vertical",
                      font: "90% verdana,arial,sans-serif"
                  }} value={patientAddress} onChange={onPatientAddressChange} />
                </FormControl>
                <FormControl fullWidth className='mt-2' variant="outlined" >
                  <input placeholder="Patient Zip Code" disabled={disableInputs}
                    style={{
                      width: "100%",
                      padding: "12px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      boxSizing: "border-box",
                      resize: "vertical",
                      font: "90% verdana,arial,sans-serif"
                  }} value={patientZip} onChange={onPatientZipChange} />
                </FormControl>
                </> 
              }
              { cardNotPresent && locationPaymentCompany === "datacap" && 
                <iframe id="payapi-token-iframe" frameborder="0" className='iframe'></iframe>
              }
              { cardNotPresent && locationPaymentCompany === "cardpointe" &&
                <iframe id="cardpointe-token-iframe" frameborder="0" scrolling="no" className='iframe'
                  src={locationPaymentIframeUrl + "?inactivityto=300&invalidcreditcardevent=true&enhancedresponse=true&usecvv=true&useexpiry=true&formatinput=true&placeholder="+encodeURIComponent("Card Number")+"&placeholdercvv=CVV&css=" + encodeURIComponent(cardpointeIFrameStyle)}></iframe>
              }
            </DialogContent>
            <DialogActions className={classes.dialogColor+ ' py-3 px-4'}>
              <Button
                className={"button-red dialog_btn"}
                variant="contained"
                color="secondary"
                startIcon={<CloseIcon />}
                onClick={handleClose}
                disabled={disableInputs}
                >Cancel</Button>
              <Button
                className="button-yellow dialog_btn"
                variant="contained"
                color="secondary"
                startIcon={<PaymentIcon />}
                onClick={submit}
                disabled={disableInputs}
               >{submitButtonText}</Button>
            </DialogActions>
          </DialogStyled>
        </Draggable>
      </div>
    );
})
export default RequestPaymentModal