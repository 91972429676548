import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.indigo[100],
    main: colors.indigo[500],
    light: colors.indigo[900]
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400']
  },
  success: {
    contrastText: white,
    dark: colors.green[400],
    main: colors.green[600],
    light: colors.green[700]
  },
  info: {
    contrastText: white,
    dark: colors.blue[400],
    main: colors.blue[600],
    light: colors.blue[700]
  },
  warning: {
    contrastText: white,
    dark: colors.yellow[400],
    main: colors.yellow[600],
    light: colors.yellow[800]
  },
  error: {
    contrastText: white,
    dark: colors.red[400],
    main: colors.red[600],
    light: colors.red[900]
  },
  pink: {
    contrastText: black,
    dark: "#ff00ff",
    main: "#ff00ff",
    light: "#ff00ff",
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#F4F6F8',
    light:"#fafafa",
    dark:"#303030"
    // paper: white
  },
  color:{
    light:"rgba(40, 40, 40, 0.87)",
    dark:"rgb(235, 235, 235)"
  },
  button:{
    light:"#fafafa",
    dark:"#303030"
  },
  bgMessanger:{
    dark:"#555555",
    light:"#303030"
  },
  colorMessanger:{
    dark:"rgba(235, 235, 235)",
    light:"#fff"
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  lightGray: {
    dark:"rgba(120, 120, 120, 0.90)",
    light:"#c0c0c0"
  },
  disabled: {
    light:"#5c5c5c",
    dark:"#8c8c8c"
  },
  toolboxButtonHover: {
    dark: colors.indigo[100],
    light: "#525282"
  },
  list: {
    dark: '#121212',
    light: '#fff'
  },
};
